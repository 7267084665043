import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useFormControl } from "../hooks/use-form-control";
import HeadShake from "../animations/headshake";
import { useOnEnterPressHook } from "../hooks/useKeyboardEvents";
import { Link, Redirect } from "react-router-dom";
import { ApiClient } from "../lib/API";
import { StoreCode } from "../lib/local-storage";
import CenterBox from "./layout/center-box";
import Zoom from "../animations/zoom";

export type EnterCodeDataProps = {};
export type EnterCodeActionProps = {};

const VALID_CODES = ["TIT", "BUNG", "TISH", "TODD", "TODDPROJECT"];
export type EnterCodeProps = EnterCodeDataProps & EnterCodeActionProps;

const EnterCode: React.FC<EnterCodeProps> = props => {
	const [attempts, setAttempts] = React.useState(0);
	const [success, set_success] = React.useState<boolean>(false);
	const [isVisible, set_isVisible] = React.useState<boolean>(false);

	const [code, codeR] = useFormControl(
		{
			placeholder: "Code"
		},
		null
	);

	const validate = React.useCallback(async () => {
		if (code) {
			const lowered = code.toUpperCase();

			try {
				const v = await ApiClient.apiStoneysListByCodeByCodeGet(lowered);
				if (v) {
					StoreCode(lowered);
					set_success(true);
					return;
				}
			} catch {}

			if (VALID_CODES.some(c => c === lowered)) {
				set_success(true);
				return;
			}
		}

		setAttempts(attempts + 1);
	}, [attempts, code]);

	const onEnterProps = useOnEnterPressHook(validate);

	const inputRef = React.useRef<HTMLInputElement>(null);
	React.useEffect(() => {
		if (inputRef && inputRef.current) {
			inputRef.current.focus();
		}
		setTimeout(() => {
			set_isVisible(true);
		}, 250);
	}, []);

	if (success === true) {
		return <Redirect to={"/pro"} />;
	}

	return (
		<CenterBox>
			<Zoom in={isVisible}>
				<div className={"d-flex flex-column mt-3 align-items-center"}>
					<div className={"mt-3 d-flex align-items-start"}>
						<Link to={"/"} className={"btn btn-link"}>
							<FontAwesomeIcon icon={faArrowLeft} size={"lg"} />
						</Link>

						<HeadShake spy={attempts}>
							{codeR({
								additionalFormGroupCSS: "mx-3",
								additionalInputProps: onEnterProps,
								inputRef
							})}
						</HeadShake>

						<button className={"btn btn-primary"} onClick={validate}>
							{"Enter"}
						</button>
					</div>
					<div className={"mt-3"}>
						<Link to={"/request-invite"}>{"Request an invite"}</Link>
					</div>
				</div>
			</Zoom>
		</CenterBox>
	);
};

export default EnterCode;
