import { DOMAttributes } from "react";

export function useOnEnterPressHook(onEnter: () => void) {
    return {
        onKeyPress: (k) => {
            if (k.key === "Enter") {
                onEnter();
            }
        }
    } as Pick<DOMAttributes<HTMLElement>, "onKeyPress">;
}