import * as React from "react";
import { useEffectPromise } from "../hooks/use-effect-promise";
import { ApiClient } from "../lib/API";
import { Listing, StoneysListDTO } from "../lib/StoneTrashAPI";
import { HashLoader } from "react-spinners";
import { CN } from "../lib/i18n";
import ListingView from "./listing-view";
import { SearchListings } from "../lib/SearchApi";

export type SearchResultsDataProps = {
	color?: string;
	size?: IDimensions;
	material?: string;
	request: StoneysListDTO;
	code: string;
};
export type SearchResultsActionProps = {};

export type SearchResultsProps = SearchResultsDataProps & SearchResultsActionProps;

const SearchResults: React.FC<SearchResultsProps> = props => {
	const { code, color, material, size, request } = props;

	const [loadingMessage, set_loadingMessage] = React.useState<string | null>(
		"Fetching Items . . ."
	);
	const [listings, set_listings] = React.useState<Listing[]>([]);
	const [message, set_message] = React.useState<string>();
	const [sentMessage, set_sentMessage] = React.useState<boolean>(false);

	useEffectPromise(async () => {
		try {
			const results = await SearchListings({ color, size, material });
			set_listings(results);
		} catch {
			set_listings([]);
		}

		set_loadingMessage(null);
	}, [color, material, size]);

	const onSendMessageClick = React.useCallback(async () => {
		try {
			if (request.adminConversationId && message) {
				set_loadingMessage("Sending Message . . .");
				await ApiClient.apiMessageCenterAddMessagePost({
					conversationId: request.adminConversationId,
					message
				});
				set_loadingMessage(null);
				set_sentMessage(true);
			}
		} catch {}
	}, [request, message]);

	if (loadingMessage) {
		return (
			<div className={"d-flex flex-column align-items-center mt-5"}>
				<h2>{loadingMessage}</h2>
				<HashLoader size={12} sizeUnit={"em"} color={"#F68E2D"} />
			</div>
		);
	}

	if (sentMessage) {
		return (
			<div className={"d-flex flex-column align-items-center mt-5"}>
				<h2>{"Message Sent!"}</h2>
				<p>{"We will get back to you shortly"}</p>
			</div>
		);
	}

	return (
		<div className={"px-0"}>
			{listings && listings.length > 0 ? (
				<div className={"mb-5 pb-5"}>
					{listings.map((l, i) => (
						<ListingView key={l.id} listing={l} request={request} code={code} />
					))}
				</div>
			) : null}

			<div className={"mb-5 pb-5"}>
				{listings.length > 1 ? (
					<div>
						<h4 className={"text-center"}>{"Can't find what you're looking for?"}</h4>
						<p>
							{
								"In the box below describe what you're looking for and we'll reach out to our network of partners to find exactly what you need."
							}
						</p>
					</div>
				) : (
					<div>
						<h4 className={"text-center"}>{"No results here, but fear not!"}</h4>
						<p>
							{
								"In the box below describe what you're looking for and we'll reach out to our network of partners to find exactly what you need."
							}
						</p>
						<p>
							{
								"Often times distributors and manufacturers are not willing to list all of their material but are very willing to move material when presented with an offer."
							}
						</p>
					</div>
				)}
				<div className={"form-group text-right"}>
					<textarea
						className={"form-control"}
						value={message}
						onChange={e => set_message(e.target.value)}
					></textarea>
					<small className={"text-right text-muted"}>{"The more details the better!"}</small>
				</div>

				<div className={"text-center text-md-left"}>
					<button
						disabled={!message || message.length < 1}
						onClick={onSendMessageClick}
						className={"btn btn-primary my-3"}
					>{`Send Request to ${CN}`}</button>
				</div>
			</div>
		</div>
	);
};

export default SearchResults;
