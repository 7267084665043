import { useEffect, useState } from "react";

export function useMedia<T>(
	queries: string[],
	values: T[],
	defaultValue: T,
	onMediaChanged?: (val: T) => void
) {
	// Array containing a media query list for each query
	const mediaQueryLists = queries.map(q => window.matchMedia(q));

	// Function that gets value based on matching media query
	const getValue = () => {
		// Get index of first media query that matches
		const index = mediaQueryLists.findIndex(mql => mql.matches);
		// Return related value or defaultValue if none
		return typeof values[index] !== "undefined" ? values[index] : defaultValue;
	};

	// State and setter for matched value
	const [value, setValue] = useState(getValue);

	useEffect(() => {
		// Event listener callback
		// Note: By defining getValue outside of useEffect we ensure that it has ...
		// ... current values of hook args (as this hook callback is created once on mount).
		const handler = () => {
			const val = getValue();

			if (onMediaChanged != null) {
				onMediaChanged(val);
			}

			setValue(val);
		};
		// Set a listener for each media query with above handler as callback.
		mediaQueryLists.forEach(mql => mql.addListener(handler));
		// Remove listeners on cleanup
		return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
	}); // Empty array ensures effect is only run on mount and unmount

	return value;
}

export function useIsMobile() {
	return useMedia(["(max-width: 576px)"], [true], false);
}

export function useIsLessThenMD() {
	return useMedia(["(max-width: 768px)"], [true], false);
}

export function useIsLessThenLG() {
	return useMedia(["(max-width: 992px)"], [true], false);
}
