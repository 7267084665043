import * as React from "react";
import moment from "moment";
export const CN = "Orange Supply Co.";
export const email = "info@orangesupply.co";
//export const CN = "StileSmarter";

export const material_size_set_button = "See Available Material";

const English = {
	material_welcome: `${CN}`,
	material_color_blurb: `What is the primary color of the material you are looking for?`,
	material_material_blurb: `Please select the material you're interested in. If you're open to any material, press the orange button.`,
	material_size_blurb: `If you need a specific dimension, select it from the dropdown or enter it manually. If you're open to any dimension, press '${material_size_set_button}'.`,
	material_size_set_button,
	footer: `Copyright ${moment().year()} ${CN}`
};

export default English;
