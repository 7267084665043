import * as React from "react";
import { Listing, ImageMetaData } from "../lib/StoneTrashAPI";
import { BASE_URL } from "../lib/API";
import { getDisplaySpecial } from "./formatting/decimal";
import {
	GetSlabBoxString,
	GetThicknessDisplay,
	CamelCaseSplit,
	FirstCap
} from "../lib/string-services";
import If from "./If";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faSquare as faSolidSquare } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import ColorGlyph from "./color-glyph";

export type ListingResultDataProps = {
	listing: Listing;
};
export type ListingResultActionProps = {};

export type ListingResultProps = ListingResultDataProps & ListingResultActionProps;

export function GetImageUrl(i: ImageMetaData | null, maxImageSize: number | null = null) {
	if (!i) return null;
	var imgSize = maxImageSize ? `?maxImageSize=${maxImageSize}` : "";
	return i.id
		? `${BASE_URL}/api/Photos/${i.id}${imgSize}`
		: `${BASE_URL}/api/Photos/Listing/${i.listingID}${imgSize}`;
}

const ListingResult: React.FC<ListingResultProps> = props => {
	const { listing } = props;

	const url = `/l/${listing.id}`;
	const colors =
		listing && listing.colors_Tags ? listing.colors_Tags.map(c => c.toLowerCase()) : null || [];

	const materialLinks = listing.materials_Tags
		? listing.materials_Tags.map(m => (
				<Link className={"mr-2 badge badge-dark"} to={`/search?material=${m}`}>
					{FirstCap(m)}
				</Link>
		  ))
		: null;

	return (
		<div className="item col-xs-12 col-md-6 col-lg-4 col-xl-3 px-1 px-md-2">
			<div className="card listingResult font-proxima mb-3 mb-md-4 rounded-0">
				<div className="listing-thumb">
					<Link to={url}>
						<img
							src={
								GetImageUrl(
									listing.images && listing.images.length > 0 ? listing.images[0] : null,
									400
								) || ""
							}
							alt={`${listing && listing.colors_Tags ? listing.colors_Tags.join(" | ") : ""} ${
								listing.title
							}`}
						/>
					</Link>
				</div>
				<div className="card-body border-top px-2 bg-white py-2">
					<div className={"align-self-stretch d-flex align-items-end justify-content-between"}>
						<span>{materialLinks}</span>
					</div>
					<Link to={url} className="text-dark">
						<h4 className="tile">{listing.title}</h4>
					</Link>
					<div className="d-flex flex-column mt-2 align-items-start">
						<div className="text-size-em4 font-proxima-semibold align-self-end">
							{`${getDisplaySpecial(listing.pricePerSqFt_USD, "money")}`}{" "}
							<small>{"/ sq. ft."}</small>
						</div>
						<div className="text-size-em2 align-self-end">{`(${getDisplaySpecial(
							listing.pricePerSqFt_USD * listing.sqFtPerUnit,
							"money"
						)} / ${GetSlabBoxString(listing.format_Tag)})`}</div>
					</div>
				</div>
				<div
					className={
						"card-footer py-0 px-2 bg-white d-flex align-items-center justify-content-between"
					}
				>
					<small className={"text-muted text-uppercase"}>{`Color${
						colors.length > 1 ? "s" : ""
					}`}</small>
					<div className="d-flex flex-wrap ml-2">
						{colors.map(c => (
							<ColorGlyph color={c} key={c} />
						))}
					</div>
				</div>
				<div className={"card-footer py-0 px-2 bg-white"}>
					<div className={"d-flex"}>
						<div className={"d-flex flex-column flex-grow-1"}>
							<small className={"text-muted text-uppercase"}>{"Dimensions"}</small>
							<div>
								{`${listing.dimensionLongest_Inches}" x ${listing.dimensionShortest_Inches}"`}
							</div>
						</div>
						<div className={"d-flex flex-column align-items-end  flex-grow-1 border-left"}>
							<small className={"text-muted text-uppercase"}>{"Thickness"}</small>
							<div>{GetThicknessDisplay(listing.dimensionThickness_SixteenthInches)}</div>
						</div>
					</div>
				</div>
				<div className="card-footer text-center py-1">{`${getDisplaySpecial(
					listing.sqFtPerUnit * listing.qtyUnitsAvailable,
					"atmost"
				)} sq.ft. available`}</div>
			</div>
		</div>
	);
};

export default ListingResult;
