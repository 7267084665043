import * as React from "react";

type PromAct = (action: () => Promise<any>) => Promise<void>;


/**
 * This method will wrap an async action in a try catch logging the error (if any) to the console.
 * This will also ensure setIsLoading(false) will not be called if the component is unmounted.
 * @param setIsLoading function that'll set the loading state to true & false
 */
export function useTryAct(setIsLoading: (x: boolean) => void) {
    const isUnmountedRef = React.useRef(false);

    React.useEffect(() => {
        isUnmountedRef.current = false;

        return () => {
            isUnmountedRef.current = true;
        }
    }, [setIsLoading]);

    return React.useCallback(async (action: () => Promise<any>) => {
        setIsLoading(true);

        try {
            await action();
        } catch (e) {
            console.log(e);
            alert("An error occured");
        }

        //-- This prevents us from setting isLoading to an unMounted component
        if (!isUnmountedRef.current) {
            setIsLoading(false);
        }

    }, [setIsLoading]);
}

/**
 * This method will wrap an async action in a try catch logging the error (if any) to the console.
 * This will also ensure setIsLoading(false) will not be called if the component is unmounted.
 * If you want to pass in your own setIsLoading then @see useTryAct 
 */
export function useTryActWithLoading(initialLoading: boolean = false): [boolean, PromAct, React.Dispatch<React.SetStateAction<boolean>>] {
    const [isLoading, setIsLoading] = React.useState(initialLoading);
    const act = useTryAct(setIsLoading);
    return [isLoading, act, setIsLoading]
}