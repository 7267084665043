import * as React from "react";
import { CSSTransition } from "react-transition-group";
import "./fade.scss";
import { SelectedTransitionProps } from "./zoom";

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,

}
type TransitionStyleDict = {
    entering?: React.CSSProperties;
    entered?: React.CSSProperties;
    exiting?: React.CSSProperties;
    exited?: React.CSSProperties;
    unmounted?: React.CSSProperties;
}

const transitionStyles: TransitionStyleDict = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 }
};

export type FadeDataProps = {
    when: boolean;
    appear?: boolean;
    collapse?: boolean;
}
export type FadeActionProps = {
}

export type FadeProps = FadeDataProps & FadeActionProps & SelectedTransitionProps;

const Fade: React.FC<FadeProps> = (props) => {

    const {
        when: inProp,
        collapse,
        children,
        appear,
        ...rest
    } = props;

    return (
        <CSSTransition in={inProp} timeout={2000} classNames={"alert"} appear={appear} {...rest}>
            {state => collapse && !inProp ? null : (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    {children}
                </div>
            )}
        </CSSTransition>
    );
}


export default Fade;