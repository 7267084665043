import * as React from "react";
import ListingDetailRow from "./listing-detail-row";
import { rulerVertical } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";

export function GetThicknessString(thicknessSixteenInches: number) {
	if (thicknessSixteenInches === 1) {
		return "1/16";
	} else if (thicknessSixteenInches === 2) {
		return "1/8";
	} else if (thicknessSixteenInches === 3) {
		return "3/16";
	} else if (thicknessSixteenInches === 4) {
		return "1/4";
	} else if (thicknessSixteenInches === 5) {
		return "5";
	} else if (thicknessSixteenInches === 6) {
		return "3/8";
	} else if (thicknessSixteenInches === 8) {
		return "1/2";
	} else if (thicknessSixteenInches === 10) {
		return "5/8";
	} else if (thicknessSixteenInches === 12) {
		return "3/4";
	} else if (thicknessSixteenInches === 20) {
		return "1-1/4";
	} else if (thicknessSixteenInches === 32) {
		return "2";
	} else return `${thicknessSixteenInches}/16`;
}

type RowThicknessDataProps = {
	dimensionThickness_SixteenthInches: number;
};
type RowThicknessActionProps = {};

library.add(rulerVertical);

const RowThickness: React.SFC<
	RowThicknessDataProps & RowThicknessActionProps
> = props => {
	const { dimensionThickness_SixteenthInches } = props;

	return (
		<ListingDetailRow
			glyph={rulerVertical}
			leftText={"Thickness"}
			right={`${GetThicknessString(dimensionThickness_SixteenthInches)}"`}
		/>
	);
};

export default RowThickness;
