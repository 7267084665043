import * as React from "react";
import { useFormControl, useNumOnlyFormControl } from "../hooks/use-form-control";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faCheck } from "@fortawesome/pro-light-svg-icons";
import Zoom from "../animations/zoom";
import { useInput } from "../hooks/use-input";
import { HashLoader } from "react-spinners";
import { ApiClient } from "../lib/API";
import OSC from "./osc";
import Logo from "./logo";
import CenterBox from "./layout/center-box";

export type SLRDataProps = {};

export type SLRActionProps = {};

export type SLRProps = SLRDataProps & SLRActionProps;

type DEMO =
	| "Architect"
	| "Consultant"
	| "Designer"
	| "Developer"
	| "Distributor"
	| "General Contractor"
	| "Owner"
	| "Procurement Specialist"
	| "Project Manager"
	| "Retailer"
	| "Trade Contractor"
	| "Other";
const ALL_DEMOS: DEMO[] = [
	"Architect",
	"Consultant",
	"Designer",
	"Developer",
	"Distributor",
	"General Contractor",
	"Owner",
	"Procurement Specialist",
	"Project Manager",
	"Retailer",
	"Trade Contractor",
	"Other"
];
const OTHER_DEMO: DEMO = "Other";

const useSimpleForm = (label: string) => useFormControl({ label }, { required: true });

const MASK = "(XXX)-XXX-XXXX";

function printMask(num: number) {
	if (num <= 0) return MASK;

	let val = num.toString();

	const first = val.substr(0, 3);
	const secon = val.substr(3, 3) || "";
	const thir = val.substr(6, 4) || "";

	return `(${first.padEnd(3, "X")})-${secon.padEnd(3, "X")}-${thir.padEnd(4, "X")}`;
}

const SLR: React.FC<SLRProps> = props => {
	const [fn, fnR, fv] = useSimpleForm("Name");
	const [ln, lnR, lv] = useSimpleForm("Company");
	const [em, emR, ev] = useFormControl(
		{
			label: "E-Mail"
		},
		{
			required: true,
			email: true
		}
	);

	const [phoneNum, setPhoneNum] = React.useState(0);

	const [ph, pR, pv] = useNumOnlyFormControl(
		{
			label: "Phone",
			helpText: printMask(phoneNum)
		},
		{
			required: true,
			isValid: v => {
				if (v <= 0) return false;

				var len = v.toString();

				return len.length === 10;
			}
		}
	);

	React.useEffect(() => {
		setPhoneNum(ph);
	}, [ph]);
	const [state, setState] = React.useState<{ step: number; isBack: boolean }>({
		isBack: false,
		step: 0
	});

	const [demo, setDemo] = React.useState<DEMO | string | null>();
	const [otherDemo, setOtherDemo] = React.useState<string>("");
	const [breif, breifProps] = useInput("");
	const [location, locationProps] = useInput("");

	const { step, isBack } = state;

	const setStep = React.useCallback((x: number) => setState({ ...state, step: x }), [
		state,
		setState
	]);

	const next = React.useCallback(() => setState({ isBack: false, step: state.step + 1 }), [state]);
	const prev = React.useCallback(() => setState({ isBack: true, step: state.step - 1 }), [state]);

	const onExited = React.useCallback(() => {
		setStep(step + (isBack ? -1 : 1));
	}, [isBack, step, setStep]);

	React.useEffect(() => {
		if (step === 10) {
			ApiClient.apiStoneysListCreatePost({
				email: em,
				coverage: 1000,
				firstName: fn,
				lastName: ln,
				role: (demo === OTHER_DEMO ? otherDemo : demo) || "Unknown",
				phone: ph.toString(),
				notes: breif + "\n\n" + location,
				format: "Unknown",
				materials: ["Unknown"],
				location: {
					latitude: 3,
					longitude: 2,
					name: "Not Bohemia",
					zip: "11716"
				},
				project: "Orange Supply Co.",
				timeFrame: 90,
				typeOfWork: "Commercial"
			}).then(() => {
				next();
			});
		}

		if (step === 12) {
			setTimeout(() => {
				next();
			}, 2500);
		}
	}, [step, next]);

	const isContactValid = fv && lv && ev && pv;
	const isDemoValid = demo === OTHER_DEMO ? !!otherDemo : !!demo;

	return (
		<CenterBox>
			<div className={"text-white text-left mt-2 mt-md-5"}>
				<Zoom in={step === 0} unmountOnExit onExited={onExited}>
					<div>
						<div className={"text-center"}>
							<Logo />
							<h1 className="cover-heading shadowText">
								<OSC />
							</h1>
						</div>
						<h4>{"Invite Request"}</h4>
						<p>
							{"Please tell us a little about yourself and your company so we can get started."}
						</p>
						<button className={"btn btn-primary"} onClick={() => setStep(1)}>
							<span className={"mr-2"}>{"Let's Begin"}</span>
							<FontAwesomeIcon icon={faArrowRight} />
						</button>
					</div>
				</Zoom>

				<Zoom in={step === 2} unmountOnExit onExited={onExited}>
					<div className={"d-flex flex-column mt-2 mt-md-5"}>
						<h4>{"Contact Details"}</h4>
						<div className={"row"}>
							<div className={"col-md-6"}>{fnR()}</div>
							<div className={"col-md-6"}>{lnR()}</div>
						</div>
						<div className={"row"}>
							<div className={"col-md-6"}>{emR()}</div>
							<div className={"col-md-6"}>
								{pR({
									additionalInputProps: {
										type: "tel"
									}
								})}
							</div>
						</div>
						<button
							className={"btn btn-primary align-self-start"}
							disabled={!isContactValid}
							onClick={next}
						>
							<span className={"mr-2"}>{"Next"}</span>
							<FontAwesomeIcon icon={faArrowRight} />
						</button>
					</div>
				</Zoom>

				<Zoom in={step === 4} unmountOnExit onExited={onExited}>
					<div className={"d-flex flex-column mt-2 mt-md-5"}>
						<h4>{"Role"}</h4>

						<label>{"What best describes your role at your company?"}</label>
						<select
							className="custom-select"
							value={demo as string}
							onChange={c => {
								const v = c.target.value;
								setDemo(v);
								if (demo === OTHER_DEMO) {
									setOtherDemo("");
								}
							}}
							aria-label="Example select with button addon"
						>
							<option value={""}>Choose...</option>
							{ALL_DEMOS.map(d => (
								<option key={d} value={d}>
									{d}
								</option>
							))}
						</select>
						{demo === OTHER_DEMO ? (
							<input
								className={"form-control mt-2"}
								placeholder={"Please specify what best describes your role on the project"}
								onChange={e => setOtherDemo(e.target.value)}
								value={otherDemo}
							/>
						) : null}

						<div className={"d-flex mt-5"}>
							<button className={"btn btn-dark mr-3"} onClick={prev}>
								<FontAwesomeIcon icon={faArrowLeft} />
								<span className={"ml-2"}>{"Prev"}</span>
							</button>
							<button className={"btn btn-primary"} disabled={!isDemoValid} onClick={next}>
								<span className={"mr-2"}>{"Next"}</span>
								<FontAwesomeIcon icon={faArrowRight} />
							</button>
						</div>
					</div>
				</Zoom>

				<Zoom in={step === 6} unmountOnExit onExited={onExited}>
					<div className={"d-flx flex-column mt-2 mt-md-5"}>
						<h4>{"Location"}</h4>
						<label>{"Where is the project located?"}</label>

						<input
							{...locationProps}
							className={"form-control"}
							placeholder={"Address, Zip or Area (NYC / Boston, MA)"}
						/>

						<div className={"d-flex mt-5"}>
							<button className={"btn btn-dark mr-3"} onClick={prev}>
								<FontAwesomeIcon icon={faArrowLeft} />
								<span className={"ml-2"}>{"Prev"}</span>
							</button>
							<button className={"btn btn-primary"} disabled={!location} onClick={next}>
								<span className={"mr-2"}>{"Next"}</span>
								<FontAwesomeIcon icon={faArrowRight} />
							</button>
						</div>
					</div>
				</Zoom>

				<Zoom in={step === 8} unmountOnExit onExited={onExited}>
					<div className={"d-flx flex-column mt-2 mt-md-5"}>
						<h4>{"Project Details"}</h4>
						<p>{"In the space below breifly describe your project and what you're looking for."}</p>
						<p>
							{
								"After you submit the request, you will be assigned a dedicated procurement principal. This individual will reach out to you within "
							}
							<span className={"text-primary"}>{"24 hours"}</span>
							{" to follow up."}
						</p>

						<textarea
							className={"form-control"}
							{...breifProps}
							style={{ minHeight: "5rem" }}
						></textarea>
						<small className="form-text text-muted">
							{"Please include approximate square footage requirements for all materials."}
						</small>

						<div className={"d-flex mt-5"}>
							<button className={"btn btn-dark mr-3"} onClick={prev}>
								<FontAwesomeIcon icon={faArrowLeft} />
								<span className={"ml-2"}>{"Prev"}</span>
							</button>
							<button
								className={"btn btn-primary"}
								disabled={!isContactValid || !isDemoValid || !breif}
								onClick={next}
							>
								{"Submit Request"}
							</button>
						</div>
					</div>
				</Zoom>

				<Zoom in={step === 10} unmountOnExit onExited={onExited}>
					<div className={"text-center d-flex flex-column mt-2 mt-md-5"} style={finalBoxHeight}>
						<h5>{"Submitting your request"}</h5>
						<div className={"align-self-center text-primary mt-3"}>
							<HashLoader size={40} sizeUnit={"px"} color={"#F68E2D"} />
						</div>
					</div>
				</Zoom>

				<Zoom in={step === 12} unmountOnExit onExited={onExited}>
					<div
						className={"d-flex flex-column align-items-center mt-2 mt-md-5"}
						style={finalBoxHeight}
					>
						<FontAwesomeIcon className={"text-primary"} icon={faCheck} size={"2x"} />
						<h3> {"All set!"}</h3>
					</div>
				</Zoom>

				<Zoom in={step === 14} unmountOnExit onExited={onExited}>
					<div className={"text-center d-flex flex-column mt-2 mt-md-5"} style={finalBoxHeight}>
						<h3>{"Request Sent!"}</h3>
						<p>
							{"We have received your request. You'll hear from us within "}
							<span className={"text-primary"}>{"24 Hours"}</span>
							{"."}
						</p>
						<p>{"Have a wonderful day"}</p>
					</div>
				</Zoom>
			</div>
		</CenterBox>
	);
};

const finalBoxHeight: React.CSSProperties = {
	height: "8rem"
};

export default SLR;
