import * as React from "react";
import OSC from "./osc";
import FindMaterial, { FIND_ROOT, ROUTE_MATERIAL_RESULTS } from "./pro/find-material";
import { Route, Switch, Redirect } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { useEffectPromise } from "../hooks/use-effect-promise";
import { GetCode, StoreCode } from "../lib/local-storage";
import { ApiClient } from "../lib/API";
import { StoneysListDTO } from "../lib/StoneTrashAPI";
import { HashLoader } from "react-spinners";
import { CN, email } from "../lib/i18n";
import MyMaterial from "./pro/my-material";
import MyProject from "./pro/my-project";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";

export type ProDataProps = {};
export type ProActionProps = {};

export type ProProps = ProDataProps & ProActionProps;

const ROUTE_PRO_MY_MATERIAL = "/pro/my-material";
const ROUTE_PRO_HOME = "/pro";
const ROUTE_PRO_FIND = FIND_ROOT;
const ROUTE_PRO_MY_PROJECT = "/pro/project-details";

const Pro: React.FC<ProProps> = () => {
	const [code] = React.useState(() => GetCode());
	const [curRequest, set_curRequest] = React.useState<StoneysListDTO | null | undefined>();

	useEffectPromise(async () => {
		if (code) {
			try {
				const slr = await ApiClient.apiStoneysListByCodeByCodeGet(code);

				if (slr) {
					setTimeout(() => {
						set_curRequest(slr);
					}, 1000);
					return;
				}
			} catch {}
		}

		//-- Error or No Code
		set_curRequest(null);
	}, [code]);

	if (!code) {
		return <Redirect to={"/"} />;
	}

	if (undefined === curRequest) {
		return (
			<div className={"d-flex flex-column align-items-center mt-5"}>
				<HashLoader size={40} sizeUnit={"px"} color={"#F68E2D"} />
			</div>
		);
	} else if (null === curRequest) {
		StoreCode();
		return <Redirect to={"/"} />;
	}

	return (
		<div className={"container h-100"}>
			<div className={"d-flex flex-column h-100 w-100"}>
				<div className={"my-3"}>
					<nav className="navbar navbar-expand-md navbar-dark border-bottom border-primary pb-2 pb-md-0">
						<Link to={"/pro"} className={"navbar-brand text-white"}>
							<OSC />
						</Link>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navBar"
							aria-controls="navBar"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse" id="navBar">
							<ul className="navbar-nav mr-auto">
								{CreateNavLink(ROUTE_PRO_HOME, "Home")}
								{CreateNavLink(ROUTE_PRO_MY_MATERIAL, "My Material")}
								{CreateNavLink(ROUTE_PRO_FIND, "Find Material", false)}
								{CreateNavLink(ROUTE_PRO_MY_PROJECT, "Project Details", false)}
							</ul>
							<ul className={"navbar-nav"}>
								<li className={"text-right"}>
									<a href={`mailto:${email}`} title={`E-Mail ${CN}`}>
										<FontAwesomeIcon icon={faEnvelope} size={"lg"} />
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
				<div className={"h-100 overflow-auto px-md-2 px-1"}>
					<Switch>
						<Route
							path={ROUTE_PRO_HOME}
							exact
							render={rp => (
								<div>
									<h3>{`Welcome to ${CN}`}</h3>
									<p>
										{
											"Here you'll have access to view our list of material. All of the material has at least 15,000 sq. ft. available."
										}
									</p>
									<p>
										{
											"Add material to your list and we'll get back to you with pricing and availability."
										}
									</p>
									<Link to={ROUTE_PRO_FIND} className={"btn btn-primary"}>
										{"Find Material"}
									</Link>
									<Link to={ROUTE_MATERIAL_RESULTS} className={"btn btn-primary ml-3"}>
										{"See All Material"}
									</Link>
								</div>
							)}
						/>
						<Route
							path={ROUTE_PRO_MY_MATERIAL}
							render={() => <MyMaterial request={curRequest} code={code} />}
						/>
						<Route
							path={ROUTE_PRO_MY_PROJECT}
							render={() => (
								<MyProject request={curRequest} onRequestChanged={set_curRequest} code={code} />
							)}
						/>
						<Route
							path={ROUTE_PRO_FIND}
							render={() => <FindMaterial request={curRequest} code={code} />}
						/>
					</Switch>
				</div>
			</div>
		</div>
	);
};

export function CreateNavLink(url: string, title: string, exact: boolean = true) {
	return (
		<li className={`nav-item`}>
			<NavLink to={url} exact={exact} activeClassName={"active"} className={"nav-link"}>
				{title}
			</NavLink>
		</li>
	);
}

{
	/* <li className="nav-item dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="#"
									id="dropdown03"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									Dropdown
								</a>
								<div className="dropdown-menu" aria-labelledby="dropdown03">
									<a className="dropdown-item" href="#">
										Action
									</a>
									<a className="dropdown-item" href="#">
										Another action
									</a>
									<a className="dropdown-item" href="#">
										Something else here
									</a>
								</div>
							</li> */
}

export default Pro;
