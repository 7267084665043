import * as React from "react";
import { CSSTransition } from "react-transition-group";
import "./zoom.scss";
import { TransitionProps } from "react-transition-group/Transition";

export type ZoomDataProps = {
}
export type ZoomActionProps = {
};

export type SelectedTransitionProps = Pick<TransitionProps, "addEndListener" | "onEnter" | "onEntering" | "onEntered" | "onExit" | "onExiting" | "onExited" | "unmountOnExit" | "in">;
export type ZoomProps = ZoomDataProps & ZoomActionProps & SelectedTransitionProps;

const Zoom: React.FC<ZoomProps> = (props) => {
    const {
        children,
        ...rest
    } = props;

    return (
        <CSSTransition timeout={305} {...rest} classNames={"zoom"} mountOnEnter>
            {children}
        </CSSTransition>
    );
}


export default Zoom;