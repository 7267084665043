export async function getImageDimensions(imgUrl: string) {
	var prom = new Promise<{ width: number; height: number }>(r => {
		var img = new Image();

		img.addEventListener("load", function() {
			r({
				height: this.naturalHeight,
				width: this.naturalWidth
			});
		});
		img.addEventListener("error", function() {
			r({ height: 0, width: 0 });
		});
		img.src = imgUrl;
	});

	const r = await prom;

	return r;
}
