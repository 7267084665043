import { useEffect, DependencyList } from "react";

export function useEffectPromise<T>(
	effect: () => Promise<T>,
	deps?: DependencyList
) {
	useEffect(() => {
		effect();
	}, deps);
}
