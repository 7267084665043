import * as React from "react";
import { StoneysListDTO, Listing } from "../../lib/StoneTrashAPI";
import ListingView from "../listing-view";
import { useEffectPromise } from "../../hooks/use-effect-promise";
import { useTryActWithLoading } from "../../hooks/use-safe-loading-actions";
import { ApiClient } from "../../lib/API";
import { HashLoader } from "react-spinners";

export type MyMaterialDataProps = {
	code: string;
	request: StoneysListDTO;
};
export type MyMaterialActionProps = {};

export type MyMaterialProps = MyMaterialDataProps & MyMaterialActionProps;

const MyMaterial: React.FC<MyMaterialProps> = props => {
	const { code, request } = props;

	const [isLoading, act] = useTryActWithLoading(false);
	const [listings, set_listings] = React.useState<Listing[]>([]);

	useEffectPromise(
		() =>
			act(async () => {
				const req = await ApiClient.apiStoneysListByCodeByCodeGet(code);

				if (req && req.listings && req.listings.length > 0) {
					const res = await ApiClient.apiListingsGetListingsPost(
						{
							listingIds: req.listings.map(l => l.listingId)
						},
						undefined
					);

					if (res && res.listings) {
						set_listings(res.listings);
					}
				}
			}),
		[]
	);

	if (isLoading) {
		return (
			<div className={"d-flex flex-column align-items-center"}>
				<HashLoader size={22} sizeUnit={"em"} color={"#F68E2D"} />
			</div>
		);
	}

	return (
		<div>
			<h3>{"My Material"}</h3>
			{listings.length > 0 ? (
				<div>
					{listings.map(l => (
						<ListingView key={l.id} listing={l} request={request} code={code} />
					))}
				</div>
			) : null}
		</div>
	);
};

export default MyMaterial;
