import * as React from "react";

export type CenterBoxDataProps = {
	noMaxWidth?: boolean;
};
export type CenterBoxActionProps = {};

export type CenterBoxProps = CenterBoxDataProps & CenterBoxActionProps;

const CenterBox: React.FC<CenterBoxProps> = props => {
	return (
		<div
			className={`container h-100 ${
				props.noMaxWidth ? "" : "max-center-box-width"
			}`}
		>
			<div className="row h-100 justify-content-center align-items-center text-center">
				<div className="col-12">{props.children}</div>
			</div>
		</div>
	);
};

export default CenterBox;
