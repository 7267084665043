import * as React from "react";

type SpecialPrint = "percent" | "money" | "tax" | "atmost";

type ValueProps = {
	value: number;
	className?: string;
};
type DecimalDataProps = ValueProps & {
	decimalPlaces?: number;
	special?: SpecialPrint;
	hideSymbol?: boolean;
};
type DecimalActionProps = {};

const taxFormat: Intl.NumberFormatOptions = {
	maximumFractionDigits: 3
};

const atMostFormat: Intl.NumberFormatOptions = {
	maximumFractionDigits: 1
};

const currencyFormat: Intl.NumberFormatOptions = {
	style: "currency",
	currency: "USD",
	currencyDisplay: "symbol"
};

export function getFixed(num: number, fixed: number = 2) {
	var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
	const match = num.toString().match(re);
	if (match) {
		return parseFloat(match[0]);
	}
	return 0;
}

export function getDisplaySpecial(
	value: number,
	special: SpecialPrint,
	noTrimZero?: boolean
) {
	return getDisplayValue(value, undefined, special, false, noTrimZero);
}

export function getDisplayValue(
	value: number,
	decimalPlaces?: number,
	special?: SpecialPrint,
	hideSymbol?: boolean,
	noTrimZero?: boolean
) {
	if (value === null || value === undefined) return "";

	if (special) {
		if (special === "money") {
			let val = value.toLocaleString(undefined, currencyFormat);

			if (val.endsWith(".00") && !noTrimZero) {
				val = val.substr(0, val.length - 3);
			}

			return hideSymbol ? val.substr(1) : val;
		} else if (special === "percent") {
			return `${(value / 100).toFixed(decimalPlaces || 0)}%`;
		} else if (special === "tax") {
			return `${(value * 100).toLocaleString(undefined, taxFormat)}%`;
		} else if (special === "atmost") {
			return value.toLocaleString(
				undefined,
				decimalPlaces ? { maximumFractionDigits: decimalPlaces } : atMostFormat
			);
		}
	}

	return decimalPlaces !== null && value !== undefined
		? value.toFixed(decimalPlaces)
		: value.toString();
}

const Decimal: React.SFC<DecimalDataProps & DecimalActionProps> = props => {
	const displayValue = getDisplayValue(
		props.value,
		props.decimalPlaces,
		props.special,
		props.hideSymbol
	);

	return (
		<span title={props.value.toString()} className={props.className}>
			{displayValue}
		</span>
	);
};

type MoneyValueProps = ValueProps & { hideSymbol?: boolean };
export const Money: React.SFC<MoneyValueProps> = props => (
	<Decimal
		value={props.value}
		special={"money"}
		hideSymbol={props.hideSymbol}
		className={props.className}
	/>
);
export const MoneyCell: React.SFC<MoneyValueProps> = props => (
	<td className={"text-right"}>
		<Money {...props} />
	</td>
);
export const Tax: React.SFC<ValueProps> = props => (
	<Decimal value={props.value} special={"tax"} className={props.className} />
);

export default Decimal;
