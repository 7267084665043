import * as React from "react";
import RowColors from "./listing-details/row-colors";
import RowFeatures from "./listing-details/row-features";
import RowStock from "./listing-details/row-stock";
import RowMaterials from "./listing-details/row-materials";
import RowPrice from "./listing-details/row-price";
import RowThickness from "./listing-details/row-thickness";
import RowDimensions from "./listing-details/row-dimensions";
import WideBox from "./wide-box";
import { Listing, StoneysListDTO } from "../lib/StoneTrashAPI";
import { ApiClient } from "../lib/API";
import { CN } from "../lib/i18n";

export type ListingViewDataProps = {
	listing: Listing;
	request: StoneysListDTO;
	code: string;
};
export type ListingViewActionProps = {};

export type ListingViewProps = ListingViewDataProps & ListingViewActionProps;

const ListingView: React.FC<ListingViewProps> = props => {
	const { listing: l, request: r, code } = props;

	const [isAddingToMyMaterial, set_isAddingToMyMaterial] = React.useState<boolean>(false);

	const [isMyListing, set_isMyListing] = React.useState<boolean>(() => {
		const lis = r.listings || [];
		return lis.some(rl => rl.listingId === l.id);
	});

	const myl = r.listings ? r.listings.find(rl => rl.listingId === l.id) : null;

	const myFind = r.listings
		? r.listings.find(rl => rl.listingId === l.id && rl.addedByUserId === (r.user || {}).id)
		: null;

	return (
		<div className="card text-dark mb-3 col-12 listing-card ">
			<div className="row">
				<div className={"p-3 mb-3 d-flex flex-column"}>
					<div className={"d-flex justify-content-between align-items-start"}>
						<h5 className={"pl-2 pl-lg-0"}>{l.title}</h5>
						{myFind ? (
							<span className={"badge badge-pill badge-primary"} title={`Added by ${CN}`}>
								{"ST"}
							</span>
						) : null}
					</div>
					<div className={"row"}>
						<div className={"col-lg-3"}>
							<img src={getImgUrl(l)} className="card-img" alt="..." />
						</div>
						<div className={"col-lg-9 col-12"}>
							<WideBox
								left={
									<>
										<RowDimensions
											dimensionLongest_Inches={l.dimensionLongest_Inches || 0}
											dimensionShortest_Inches={l.dimensionShortest_Inches || 0}
										/>
										<RowThickness
											dimensionThickness_SixteenthInches={l.dimensionThickness_SixteenthInches || 0}
										/>
										<RowPrice pricePerSqFt_USD={l.pricePerSqFt_USD || 0} />
										<RowPrice pricePerSqFt_USD={l.pricePerSqFt_USD || 0} totalSqFt={r.coverage} />
									</>
								}
								right={
									<>
										<RowMaterials materials={l.materials_Tags || []} />
										{/* <RowLocation location={l.location} /> */}
										<RowStock
											qtyAvailable={l.qtyUnitsAvailable || 0}
											format={l.format_Tag || ""}
											sqftPerUnit={l.sqFtPerUnit || 0}
										/>
										<RowFeatures features={l.finishes || []} />
										<RowColors colors={l.colors_Tags || []} />
									</>
								}
							/>
							{myl && myl.rejectionMessage ? (
								<div className={"alert alert-primary mt-2"}>{myl.rejectionMessage}</div>
							) : null}
							<div>
								<button
									className={"btn btn-dark mt-2"}
									disabled={isAddingToMyMaterial || isMyListing}
									onClick={async () => {
										ApiClient.apiStoneysListAddListingPost(r.id, l.id, code);
										set_isMyListing(true);
									}}
								>
									{"Add to My Material"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export function getImgUrl(l: Listing) {
	if (l) {
		if (l.images) {
			if (l.images[0]) {
				if (l.images[0].url) {
					return l.images[0].url;
				}
			}
		}
	}

	return "..";
}

export default ListingView;
