import { ApiClient } from "./API";

export type ISize = {
	width: number;
	length: number;
};

export type StringSearchOptions = {
	color?: string;
	material?: string;
	manufacturer?: string;
	keyword?: string;
	finish?: string;
};

export type SearchOptions = StringSearchOptions & {
	size?: ISize;
};

export async function SearchListings(options?: SearchOptions) {
	const {
		color = null,
		size = null,
		material = null,
		manufacturer = null,
		keyword = undefined,
		finish = undefined
	} = options || {};
	const searchOptions: string[] = ["min-sq-ft-available:15000"];
	if (color) {
		searchOptions.push(`color:${color}`);
	}

	if (finish) {
		searchOptions.push(`finish:${finish}`);
	}

	if (size) {
		const min = Math.min(size.length, size.width);
		const max = Math.max(size.length, size.width);
		searchOptions.push(`min-dimension-shortest-in:${min}`);
		searchOptions.push(`max-dimension-shortest-in:${min}`);
		searchOptions.push(`min-dimension-longest-in:${max}`);
		searchOptions.push(`max-dimension-longest-in:${max}`);
	}

	if (material) {
		searchOptions.push(`material:${material.toLowerCase()}`);
	}

	if (manufacturer) {
		searchOptions.push(`manufacturer:${manufacturer.toLowerCase()}`);
	}

	const results = await ApiClient.apiListingsSearchListingsPost(
		{
			searchOptions,
			query: keyword
		},
		undefined
	);

	return (results || ({} as any)).results || [];
}
