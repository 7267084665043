import { OrderLineItemListingSnapshot } from "./StoneTrashAPI";

export function FirstCap(val: string) {
	if (val && val.length > 0) {
		return `${val.substring(0, 1).toUpperCase()}${val.substr(1)}`;
	}
	return val;
}

export function CamelCaseSplit(val: string) {
	if (!val) return "";

	return val.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
}

export function shortAnonId(anonId: string) {
	if (!anonId) return "ANON";

	if (anonId.length !== 36) {
		return anonId;
	}

	return anonId.substr(24);
}

export function IsSlab(type?: string | null) {
	return type === "Slab";
}

export function GetSlabBoxString(type?: string | null, count: number = 0) {
	const isSlab = IsSlab(type);
	return `${isSlab ? "Slab" : "Box"}${count > 1 ? (isSlab ? "s" : "es") : ""}`;
}
export function SlabBoxString(s: OrderLineItemListingSnapshot, count: number = 0) {
	return GetSlabBoxString(s.format_Tag, count);
}

interface IThickness {
	amount: number;
	displayValue: string;
}

const createThick = (amount: number, displayValue: string): IThickness => ({
	amount,
	displayValue
});

const Thicknesses = {
	[1]: createThick(1, `1/16"`),
	[2]: createThick(2, `1/8"`),
	[3]: createThick(3, `3/16"`),
	[4]: createThick(4, `1/4"`),
	[5]: createThick(5, `5/16"`),
	[6]: createThick(6, `3/8"`),
	[8]: createThick(8, `1/2"`),
	[10]: createThick(10, `5/8"`),
	[12]: createThick(12, `3/4"`),
	[16]: createThick(16, `1"`),
	[20]: createThick(20, `1-1/4"`),
	[32]: createThick(32, `2"`)
};

export const AllThicknesses = [
	Thicknesses[1],
	Thicknesses[2],
	Thicknesses[3],
	Thicknesses[4],
	Thicknesses[5],
	Thicknesses[6],
	Thicknesses[8],
	Thicknesses[10],
	Thicknesses[12],
	Thicknesses[16],
	Thicknesses[20],
	Thicknesses[32]
];

export const SlabThickness = [
	Thicknesses[2],
	Thicknesses[3],
	Thicknesses[4],
	Thicknesses[6],
	Thicknesses[8],
	Thicknesses[10],
	Thicknesses[12],
	Thicknesses[16],
	Thicknesses[20],
	Thicknesses[32]
];

export const TileThicknesses = [
	Thicknesses[3],
	Thicknesses[4],
	Thicknesses[5],
	Thicknesses[6],
	Thicknesses[8],
	Thicknesses[10],
	Thicknesses[12]
];

export function GetThicknessDisplay(val: number) {
	const r = (Thicknesses as any)[val] as IThickness;
	if (r) {
		return r.displayValue;
	}
	return `${val} / 16th`;
}
