import React from "react";
import "./App.scss";
import EnterCode from "./components/enter-code";
import SLR from "./components/slr";
import { hot } from "react-hot-loader";
import "bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { mountain, mountains, seedling, handHoldingSeedling, leaf, appleAlt } from "./lib/duotone";
import WelcomeComponent from "./components/welcome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Pro from "./components/pro";
import SearchContainer from "./components/search-container";

library.add(mountain, mountains, seedling, handHoldingSeedling, leaf, appleAlt);

const App: React.FC = () => {
	return (
		<>
			<div className={"h-100 w-100 position-absolute fullImage"} />
			<div className={"h-100 w-100 position-absolute shadowBox"}>
				<Router>
					<Switch>
						<Route component={SLR} path="/request-invite" />
						<Route component={EnterCode} path="/enter-code" />
						<Route component={Pro} path="/pro" />
						<Route component={SearchContainer} path={["/search", "/l/:listingId"]} />
						<Route component={WelcomeComponent} path={"/"} />
					</Switch>
				</Router>
				{/* <div className={"h-100 text-center position-relative"}>
					<div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
						<main role="main" className="inner cover mt-md-auto mt-3">
							
						</main>

						<footer className="mastfoot mt-auto">
							<div className="inner">{i18n.footer}</div>
						</footer>
					</div>
				</div> */}
			</div>
		</>
	);
};

export default hot(module)(App);
