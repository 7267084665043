import * as React from "react";

type DetailRowDataProps = {
    left: React.ReactNode;
    right: React.ReactNode;
}
type DetailRowActionProps = {

    
}

const DetailRow: React.SFC<DetailRowDataProps & DetailRowActionProps> = (props) => {

    return (
        <li className="list-group-item p-0">
            <div className="row mx-0">
                <div className="col-6 col-lg-5 bg-light border-right py-2 text-center text-left d-flex align-items-center px-2 px-md-3">
                    {props.left}
                </div>
                <div className="col-6 col-lg-7 py-2 d-flex align-items-center px-2 px-md-3">
                    {props.right}
                </div>
            </div>
        </li>
    );
}


export default DetailRow;