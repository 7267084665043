import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type LogoDataProps = {};
export type LogoActionProps = {};

export type LogoProps = LogoDataProps & LogoActionProps;

const Logo: React.FC<LogoProps> = props => {
	return (
		<div className={"fa-7x"}>
			<span className="fa-layers fa-fw">
				<FontAwesomeIcon
					icon={["fad", "mountains"]}
					className={"text-primary"}
					transform={"right-6 down-2 shrink-1"}
					style={
						{
							"--fa-secondary-color": "var(--primary)",
							"--fa-primary-opacity": "0",
							"--fa-secondary-opacity": "1"
						} as any
					}
				/>
				<FontAwesomeIcon
					icon={["fad", "mountains"]}
					className={"text-primary"}
					transform={"left-6 down-2 shrink-1 flip-h"}
					style={
						{
							"--fa-secondary-color": "var(--primary)",
							"--fa-secondary-opacity": "1",
							"--fa-primary-opacity": "0"
						} as any
					}
				/>

				{/* <FontAwesomeIcon icon={["fad", "mountains"]} className={"text-primary"} transform={"right-12 down-3 shrink-3"} />
                    <FontAwesomeIcon icon={["fad", "mountains"]} className={"text-primary"} transform={"left-12 down-3 shrink-3 flip-h"} /> */}

				<FontAwesomeIcon
					icon={["fad", "mountain"]}
					className={"text-primary"}
					transform={"grow-3"}
					style={
						{
							"--fa-secondary-color": "White",
							"--fa-secondary-opacity": "1"
						} as any
					}
				/>
			</span>
		</div>
	);
};

export default Logo;
