import * as React from "react";
import ListingDetailTagsRow from "./listing-detail-tags-row";
import { thLarge } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FirstCap } from "../../lib/string-services";

type RowMaterialsDataProps = {
	materials: string[];
};
type RowMaterialsActionProps = {};
library.add(thLarge);

const RowMaterials: React.SFC<RowMaterialsDataProps & RowMaterialsActionProps> = props => {
	const { materials } = props;

	return (
		<ListingDetailTagsRow
			glyph={thLarge}
			leftText={"Material"}
			tags={materials}
			renderTag={t => (
				<div className={"mr-2"} key={t}>
					{FirstCap(t)}
				</div>
			)}
		/>
	);
};

export default RowMaterials;
