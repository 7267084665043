import * as React from "react";
import { HashLoader } from "react-spinners";
import { SearchListings, SearchOptions, StringSearchOptions, ISize } from "../lib/SearchApi";
import ListingResult from "./listing-result";
import useSWR from "swr";
import * as qs from "query-string";
import If from "./If";
import { Link, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch, faFilter } from "@fortawesome/pro-light-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { faPalette, faTh, faHandRock, faRulerCombined } from "@fortawesome/pro-duotone-svg-icons";
import { ApiClient } from "../lib/API";
import { StaticContext, RouteComponentProps } from "react-router";
import ColorGlyph from "./color-glyph";
import Select, { ValueType } from "react-select";
import { TagAccepted } from "../lib/StoneTrashAPI";
import { useIsLessThenMD } from "../hooks/useMedia";
import { useCollapsePanel } from "../hooks/use-collapse-panel";
import { dimension_options } from "./pro/find-material";

export type SearchDataProps = {};
export type SearchActionProps = {};

export type SearchProps = SearchDataProps & SearchActionProps;

const Search: React.FC<SearchProps> = () => {
	const searchOptions = searchFilter();

	const filterKey = JSON.stringify(searchOptions);

	const { data: listings } = useSWR(`SearchResults_${filterKey}`, () =>
		SearchListings(searchOptions)
	);

	if (!listings) {
		return (
			<div className={"d-flex flex-column align-items-center mt-5"}>
				{/* <h2>{loadingMessage}</h2> */}
				<HashLoader size={12} sizeUnit={"em"} color={"#F68E2D"} />
			</div>
		);
	}

	if (listings && listings.length > 0) {
		return (
			<div>
				<ActiveFilterControl {...searchOptions} />
				<div className={"row mt-3 text-dark mx-0 no-gutters"}>
					{listings.map(l => (
						<ListingResult listing={l} key={l.id} />
					))}
				</div>
			</div>
		);
	}

	const hasfilter = filterKey.length > 2;

	return (
		<div>
			<ActiveFilterControl {...searchOptions} />
			<h3 className={"display-4 border-top border-primary mt-3"}>{"No Results"}</h3>
			<p>{"I'm sorry, there are no results."}</p>
			<If condition={hasfilter}>
				{() => (
					<div>
						<Link to={"/search"}>{"See all Listings"}</Link>
					</div>
				)}
			</If>
		</div>
	);
};

const ActiveFilterControl: React.FC<SearchOptions> = p => {
	const { data: validTags } = useSWR(`ValidTags`, () => ApiClient.apiListingsGetValidTagsPost({}));
	const [search, set_search] = React.useState<string>(p.keyword || "");

	const { isCollapsed, onClick: onCollapseClick, panelRef } = useCollapsePanel(
		JSON.stringify(p).length < 5
	);

	const isSmall = useIsLessThenMD();

	if (!validTags) {
		return null;
	}

	const sizeStr = p.size ? JSON.stringify(p.size) : "";

	const performSearch = (
		rp: RouteComponentProps<any, StaticContext, any>,
		options: SearchOptions = p
	) => rp.history.push(createSearchUrl({ ...options, keyword: search }));

	return (
		<div className={"d-flex flex-column mx-1 my-2"}>
			<Route
				render={rp => (
					<div className={"d-flex justify-content-between align-items-center"}>
						<div className={"d-flex justify-content-between flex-grow-1"}>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">
										<FontAwesomeIcon icon={faSearch} />
									</span>
								</div>
								<input
									type="text"
									className="form-control border-right-0"
									value={search}
									onKeyPress={k => {
										if (k.which === 13) {
											performSearch(rp);
										}
									}}
									onChange={e => set_search(e.target.value)}
								/>
								<div className="input-group-append">
									<span className="input-group-text bg-white">
										{!!search ? (
											<FontAwesomeIcon
												icon={faTimes}
												className={"text-danger pointer"}
												onClick={() => set_search("")}
											/>
										) : null}
									</span>
								</div>
							</div>
							<button className={"btn btn-primary ml-3"} onClick={() => performSearch(rp)}>
								{"Search"}
							</button>
						</div>

						{isSmall ? null : (
							<button className={"btn btn-primary ml-4"} onClick={onCollapseClick}>
								<FontAwesomeIcon icon={isCollapsed ? faFilter : faFilterSolid} className={"mr-2"} />
								{"Filter"}
							</button>
						)}
					</div>
				)}
			/>

			{isSmall ? (
				<div>
					<button className={"btn btn-primary btn-block mt-3"} onClick={onCollapseClick}>
						<FontAwesomeIcon
							icon={isCollapsed ? faFilter : faFilterSolid}
							size={"lg"}
							className={"mr-2"}
						/>
						{"Filter"}
					</button>
				</div>
			) : null}

			<div className={"collapse"} ref={panelRef}>
				<Route
					render={rp => {
						function renderSearchDropdown(
							options: TagAccepted[],
							button: React.ReactNode,
							k: keyof StringSearchOptions,
							ren: (o: TagAccepted) => React.ReactNode = o => o.tag
						) {
							const v = p[k];
							const val = !v
								? null
								: options.find(o =>
										o.tag ? o.tag.toLowerCase() === v.toLocaleLowerCase() : false
								  );

							return (
								<div className={"d-flex flex-column my-1 flex-grow-1 mx-md-2"}>
									{/* <div className={"text-center mb-1"}>{button}</div> */}
									<Select
										className={"react-select-container"}
										classNamePrefix={"react-select"}
										isClearable
										placeholder={<div className={"text-center mb-1"}>{button}</div>}
										onChange={(v: ValueType<TagAccepted>) => {
											if (!Array.isArray(v)) {
												const t = v as TagAccepted;
												performSearch(rp, {
													...p,
													[k]: t && t.tag ? t.tag.toLowerCase() : undefined
												});
											}
										}}
										getOptionLabel={o => ren(o) as any}
										getOptionValue={o => (o && o.tag ? o.tag : "")}
										options={options as any}
										value={val}
									/>
								</div>
							);
						}

						return (
							<div className={"d-flex flex-column flex-md-row mt-3"}>
								<div className={"d-flex flex-column my-1 flex-grow-1 mx-md-2"}>
									{/* <div className={"text-center mb-1"}>
										<>
											<FontAwesomeIcon icon={faRulerCombined} size={"lg"} className={"mr-2"} />
											{"Dimensions"}
										</>
									</div> */}
									<Select
										className={"react-select-container"}
										classNamePrefix={"react-select"}
										isClearable
										placeholder={
											<>
												<FontAwesomeIcon icon={faRulerCombined} size={"lg"} className={"mr-2"} />
												{"Dimensions"}
											</>
										}
										onChange={(v: ValueType<ISize>) => {
											if (!Array.isArray(v)) {
												const t = v as ISize;
												performSearch(rp, { ...p, size: t });
											}
										}}
										getOptionLabel={s => (s ? `${s ? s.length : 0}" x ${s ? s.width : 0}"` : "")}
										getOptionValue={o => `${o.length}x${o.width} ${o.length} x ${o.width}`}
										options={dimension_options as any}
										value={p.size || ("" as any)}
									/>
								</div>
								{renderSearchDropdown(
									validTags.materials || [],
									<>
										<FontAwesomeIcon icon={faTh} size={"lg"} className={"mr-2"} />
										{"Material"}
									</>,
									"material"
								)}
								{renderSearchDropdown(
									validTags.colors || [],
									<>
										<FontAwesomeIcon icon={faPalette} size={"lg"} className={"mr-2"} />
										{"Color"}
									</>,
									"color",
									x => (
										<>
											<ColorGlyph color={x.tag as string} /> {x.tag}
										</>
									)
								)}
								{renderSearchDropdown(
									validTags.finishes || [],
									<>
										<FontAwesomeIcon icon={faHandRock} size={"lg"} className={"mr-2"} />
										{"Finish"}
									</>,
									"finish"
								)}
							</div>
						);
					}}
				/>
			</div>
		</div>
	);
};

function createSearchUrl(options: Partial<SearchOptions>) {
	let qs = Object.keys(options)
		.filter(k => {
			const key = k as keyof SearchOptions;

			return key !== "size" && !!options[key];
		})
		.reduce((p, c) => `${p}&${c}=${options[c as keyof SearchOptions]}`, "");

	//-- Special for size
	if (options.size) {
		const size = options.size;

		qs += `&len=${size.length}&wid=${size.width}`;
	}

	return qs ? `/search?${qs.substring(1)}` : `/search`;
}

const searchFilter = (): SearchOptions => {
	const parsed = qs.parse(window.location.search);

	let size: ISize | undefined = undefined;

	if (parsed["len"] && parsed["wid"]) {
		size = {
			length: parseInt(parsed["len"]),
			width: parseInt(parsed["wid"])
		};
	}

	return {
		color: parsed["color"] as string,
		material: parsed["material"] as string,
		manufacturer: parsed["manufacturer"] as string,
		finish: parsed["finish"] as string,
		keyword: parsed["keyword"] as string,
		size
	};
};

export default Search;
