import * as React from "react";
import classnames from "classnames";
import { useInput, useNumberOnlyInput } from "./use-input";

type FormControlDataProps<TInitial extends string | number> = {
    initialValue?: TInitial;
    label?: string;
    helpText?: string;
    placeholder?: string;
    readonly?: boolean;
    inputName?: string;
    type?: "text" | "password";
}

type FormControlActionProps = {
}

type FormValidationOptions<T extends string | number> = {
    /**
     * 
     */
    isValid?: (v: T) => boolean;

    /**
     * Regex to determine if value is valid. note if isValid is specified,
     * the regex will be ignored.
     */
    regex?: RegExp;
    required?: boolean;
    email?: boolean;
    validationMessage?: string;
}

export type FormControlProps<TInitial extends string | number = string> = FormControlDataProps<TInitial> & FormControlActionProps;


type useFormRenderer = (ac?: RFAdditionalProps) => React.ReactNode;

export function useNumOnlyFormControl(props: FormControlProps<number>, validation: FormValidationOptions<number> | null = null): [number, useFormRenderer, boolean] {

    const [val, inputProps,,touched] = useNumberOnlyInput(props.initialValue);
    const iprops = React.useMemo(() => (
        { ...inputProps, name: props.inputName }
    ), [inputProps, props.inputName]);

    const { valid, validationMessage } = validate(validation, val, touched);

    const render = () => RenderFormControl(props, iprops, valid, validationMessage);

    return [val, render, valid];
}

export function useFormControl(props: FormControlProps, validation: FormValidationOptions<string> | null = null): [string, useFormRenderer, boolean, (x: string) => void] {
    const [val, inputProps, setValue, touched] = useInput(props.initialValue || '');
    const iprops = React.useMemo(() => (
        { ...inputProps, name: props.inputName }
    ), [inputProps, props.inputName]);

    const { valid, validationMessage } = validate(validation, val, touched);

    const render = (addClasses?: RFAdditionalProps) => RenderFormControl(props, iprops, valid, validationMessage, addClasses);

    return [val, render, valid && touched, setValue];
}

type inputProps = Required<Pick<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, HTMLInputElement | HTMLTextAreaElement>, "value" | "onChange">> & {
    name?: string
}

interface RFAdditionalProps {
    additionalFormGroupCSS?: string;
    additionalInputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    inputRef?: React.Ref<HTMLInputElement>;
}

function RenderFormControl<T extends string | number>(metaProps: FormControlProps<T>, inputProps: inputProps, valid: boolean, validationMessage: string | null = null, addClasses?: RFAdditionalProps) {
    const {
        helpText,
        label,
        placeholder,
        readonly,
        type
    } = metaProps;

    const {
        additionalFormGroupCSS = "",
        additionalInputProps = {},
        inputRef = null,
    } = (addClasses || {});

    return (
        <div className={classnames("form-group", additionalFormGroupCSS)}>
            {label ? (<label>{label}</label>) : null}
            <input {...inputProps} readOnly={readonly} className={`form-control ${valid ? "" : "is-invalid"}`} type={type || "text"} placeholder={placeholder} {...additionalInputProps} ref={inputRef} />
            {validationMessage && !valid ? (<div className={"invalid-feedback"}>
                {validationMessage}
            </div>) : (helpText ? (<small className="form-text text-muted">{helpText}</small>) : null)}
        </div>
    );
}
// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validate<T extends string | number>(validation: FormValidationOptions<T> | null, val: T, touched: boolean) {
    const {
        isValid,
        required,
        validationMessage
    } = validation || {} as FormValidationOptions<T>;

    if (!touched) return {
        valid: true,
        validationMessage
    }

    let valid = true;

    const regex = !validation ? null : (validation.regex || (validation.email ? EMAIL_REGEX : null));

    if (required) {
        valid = !!val;
    }

    if (valid && isValid) {
        valid = valid && isValid(val);
    }
    else if (valid && regex) {
        if (typeof val === "string") {
            valid = regex.test(val);
        }
        else if (typeof val === "number" && val !== null && val !== undefined) {
            valid = regex.test(val.toString());
        }
    }

    return {
        valid,
        validationMessage
    };
}
