import * as React from "react";
import { useIsLessThenLG } from "../hooks/useMedia";

type WideBoxDataProps = {
	left: React.ReactNode;
	right: React.ReactNode;
};
type WideBoxActionProps = {};

const WideBox: React.SFC<WideBoxDataProps & WideBoxActionProps> = props => {
	const lessThenLg = useIsLessThenLG();

	const { left, right } = props;

	const leftBox = lessThenLg ? "border-top" : "border rounded";
	const rightBox = lessThenLg ? "border-top border-bottom" : "border rounded";

	return (
		<div className={"d-flex flex-column flex-lg-row"}>
			<div className={"flex-grow-1 mr-lg-2"}>
				<ul className={`list-group list-group-flush ${leftBox}`}>{left}</ul>
			</div>
			<div className={"flex-grow-1"}>
				<ul className={`list-group list-group-flush ${rightBox}`}>{right}</ul>
			</div>
		</div>
	);
};

export default WideBox;
