import { Client } from "./StoneTrashAPI";
import { email } from "./i18n";

const DEBUG = process.env.NODE_ENV === "development";

export const BASE_URL = DEBUG ? "https://localhost:44333" : "https://api.stonetrash.com";
export const ApiClient = new Client(BASE_URL, {
	fetch: (info, init) => {
		const headers = {
			...(init || {}).headers,
			ApiClient: "web",
			AnonId: ""
		};

		return fetch(info, {
			...init,
			headers,
			mode: "cors",
			credentials: "include"
		});
	}
});

export function SendEmail() {
	var currentUrl = window.location.href.replace("#", "");

	if (currentUrl.toLowerCase().indexOf("/l/") > -1) {
		window.open(
			`mailto:${email}?subject=Interesting in Material&body=Hello,%0D%0A%0D%0AI am interested in ${currentUrl}%0D%0A%0D%0AMy project and timeline is as follows:%0D%0A%0D%0A%0D%0A%0D%0A`,
			"_blank"
		);
	} else {
		window.open(`mailto:${email}`, "_blank");
	}

	//window.open(, "_blank");
}
