import * as React from "react";
import ListingDetailRow from "./listing-detail-row";
import { getDisplayValue } from "../formatting/decimal";
import { rulerCombined } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";
type RowDimensionsDataProps = {
	dimensionLongest_Inches: number;
	dimensionShortest_Inches: number;
};
type RowDimensionsActionProps = {};

library.add(rulerCombined);

const RowDimensions: React.SFC<
	RowDimensionsDataProps & RowDimensionsActionProps
> = props => {
	const { dimensionLongest_Inches, dimensionShortest_Inches } = props;

	return (
		<ListingDetailRow
			glyph={rulerCombined}
			leftText={"Dimensions"}
			right={`${getDisplayValue(
				dimensionLongest_Inches,
				2,
				"atmost"
			)}" x ${getDisplayValue(dimensionShortest_Inches, 2, "atmost")}"`}
		/>
	);
};

export default RowDimensions;
