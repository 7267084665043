import * as React from "react";
import { Switch, Route } from "react-router";
import ListingPage from "./listing-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CN } from "../lib/i18n";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import Search from "./search";
import { Link } from "react-router-dom";
import OSC from "./osc";
import { SendEmail } from "../lib/API";

export type SearchContainerDataProps = {};
export type SearchContainerActionProps = {};

export type SearchContainerProps = SearchContainerDataProps & SearchContainerActionProps;

const SearchContainer: React.FC<SearchContainerProps> = props => {
	const {} = props;

	return (
		<div className={"container h-100"}>
			<div className={"d-flex flex-column h-100 w-100"}>
				<div className={"my-3"}>
					<nav className="navbar navbar-dark border-bottom border-primary pb-2 pb-md-0 d-flex justify-content-between">
						<Link to={"/search"} className={"navbar-brand text-white"}>
							<OSC />
						</Link>
						<a onClick={SendEmail} href={"#"} title={`E-Mail ${CN}`}>
							<FontAwesomeIcon icon={faEnvelope} size={"lg"} />
						</a>
					</nav>
				</div>
				<div className={"h-100 overflow-auto"}>
					<Switch>
						<Route component={Search} path={"/search"} />
						<Route
							path={"/l/:listingId"}
							render={p => <ListingPage listingId={parseInt(p.match.params.listingId)} />}
						/>
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default SearchContainer;
