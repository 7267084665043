import * as React from "react";
import * as JQ from "jquery";

const $ = JQ as any;

export function useCollapsePanel(isCollapsedInitially: boolean = false) {
	const [isCollapsed, setIsCollapsed] = React.useState(isCollapsedInitially);
	const panelRef = React.useRef<HTMLDivElement | null>(null);

	const cb = React.useCallback(() => {
		if (!panelRef.current) return;

		updateCollapsePanel(panelRef, isCollapsed);

		setIsCollapsed(!isCollapsed);
	}, [isCollapsed, panelRef.current]);

	React.useEffect(() => {
		if (panelRef.current) {
			const p = $(panelRef.current);

			if (!isCollapsedInitially) {
				p.collapse("show");
			}
		}
	}, []);

	return {
		panelRef: (p: HTMLDivElement) => {
			if (p) {
				panelRef.current = p;
				updateCollapsePanel(panelRef, isCollapsed);
			}
		},
		onClick: cb,
		isCollapsed
	};
}

export function updateCollapsePanel(
	panelRef: React.MutableRefObject<HTMLDivElement | null>,
	isCollapsed: boolean
) {
	const p = $(panelRef.current);
	if (isCollapsed) {
		p.collapse("hide");
	} else {
		p.collapse("show");
	}
}

export function useIsCollapsed(
	isCollapsed: boolean,
	panelRef: React.MutableRefObject<HTMLDivElement | null>
) {
	React.useEffect(() => {
		updateCollapsePanel(panelRef, isCollapsed);
	}, []);

	React.useEffect(() => {
		updateCollapsePanel(panelRef, isCollapsed);
	}, [isCollapsed]);
}
