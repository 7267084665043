import * as React from "react";
import ListingDetailRow from "./listing-detail-row";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";

type ListingDetailTagsRowDataProps = {
	/**
	 * full Glyph - Ex: `fal fa-usd-money`
	 */
	glyph: IconDefinition;
	leftText: string;
	tags: string[];
};
type ListingDetailTagsRowActionProps = {
	renderTag: (t: string) => React.ReactNode;
};

const ListingDetailTagsRow: React.SFC<
	ListingDetailTagsRowDataProps & ListingDetailTagsRowActionProps
> = props => {
	const { glyph, leftText, renderTag, tags } = props;

	if (!tags || tags.length === 0) {
		return null;
	}

	return (
		<ListingDetailRow
			glyph={glyph}
			leftText={leftText}
			right={
				<div className={"d-flex flex-wrap"}>{tags.map(t => renderTag(t))}</div>
			}
		/>
	);
};

export default ListingDetailTagsRow;
