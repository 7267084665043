import * as React from "react";

export type IfDataProps = {
	condition: boolean;
};
export type IfActionProps = {};

export type IfProps = IfDataProps & IfActionProps;

const If: React.SFC<IfProps> = props => {
	const { children, condition } = props;

	if (condition) {
		return typeof children === "function" ? children() : children;
	}

	return null;
};

export default If;
