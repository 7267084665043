import * as React from "react";
import { StoneysListDTO } from "../../lib/StoneTrashAPI";
import { useWholeNumberInputProps, useInput } from "../../hooks/use-input";
import { ApiClient } from "../../lib/API";
import { useTryActWithLoading } from "../../hooks/use-safe-loading-actions";
import { BarLoader } from "react-spinners";

export type MyProjectDataProps = {
	request: StoneysListDTO;
	code: string;
};

export type MyProjectActionProps = {
	onRequestChanged: (r: StoneysListDTO) => void;
};

export type MyProjectProps = MyProjectDataProps & MyProjectActionProps;

const MyProject: React.FC<MyProjectProps> = props => {
	const { request, code, onRequestChanged } = props;

	const [coverage, coverageProps] = useWholeNumberInputProps(request.coverage);
	const [isAltered, set_isAltered] = React.useState<boolean>(false);
	const [notes, noteProps] = useInput(request.notes || "");

	const [isLoading, act] = useTryActWithLoading(false);

	React.useEffect(() => {
		set_isAltered(
			coverage !== request.coverage || notes !== (request.notes || "")
		);
	}, [coverage, notes]);

	return (
		<div>
			<h3>{"Project Details"}</h3>
			<p>{"Here you can find details about your project."}</p>

			<div className={"form-group"} style={{ maxWidth: "300px" }}>
				<label>{"Coverage"}</label>
				<div className="input-group">
					<input
						className="form-control"
						placeholder="Coverage"
						{...coverageProps}
					/>
					<div className="input-group-append">
						<span className="input-group-text">{"sq. ft."}</span>
					</div>
				</div>
			</div>

			<div className={"form-group"}>
				<label>{"Project Notes"}</label>
				<textarea
					className="form-control"
					style={{ minHeight: "8em" }}
					placeholder="Coverage"
					{...noteProps}
				/>
				<small className={"text-muted"}>
					{"The more info you provide, the better we can assist you."}
				</small>
			</div>

			<button
				className={"btn btn-primary"}
				disabled={!isAltered || isLoading || !notes || coverage <= 0}
				onClick={() => {
					act(async () => {
						await ApiClient.apiStoneysListUpdateBasicsPatch(
							request.id,
							{
								coverage,
								notes
							},
							code
						);
						const r = await ApiClient.apiStoneysListByCodeByCodeGet(code);
						onRequestChanged(r);
					});
				}}
			>
				{isLoading ? <BarLoader color={"White"} /> : "Save"}
			</button>
		</div>
	);
};

export default MyProject;
