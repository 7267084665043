import * as React from "react";
import DetailRow from "../detail-row";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type ListingDetailRowDataProps = {
	/**
	 * full Glyph - Ex: `fal fa-usd-money`
	 */
	glyph: IconProp;
	leftText: string;
	right: React.ReactNode;
};
type ListingDetailRowActionProps = {};

const ListingDetailRow: React.SFC<
	ListingDetailRowDataProps & ListingDetailRowActionProps
> = props => {
	const { glyph, leftText, right } = props;

	return (
		<DetailRow
			left={
				<>
					<FontAwesomeIcon
						fixedWidth
						size={"lg"}
						className={"mr-1 mr-lg-2"}
						icon={glyph}
					/>
					{leftText}
				</>
			}
			right={right}
		/>
	);
};

export default ListingDetailRow;
