import * as React from "react";

type InputHookProps<T extends HTMLInputElement | HTMLTextAreaElement> = Required<
	Pick<React.DetailedHTMLProps<React.InputHTMLAttributes<T>, T>, "value" | "onChange">
>;
type SetValue<T extends string | number> = (v: T) => void;

export type NumOnlyHookReponse<
	TInput extends HTMLInputElement | HTMLTextAreaElement,
	T extends string | number
> = [string, InputHookProps<TInput>, SetValue<T>];

export function useInput<T extends HTMLInputElement | HTMLTextAreaElement>(
	initialValue: string,
	matcher: RegExp | null = null,
	transform: ((f: string) => string) | null = null
): [string, InputHookProps<T>, SetValue<string>, boolean] {
	const [value, setValue] = React.useState(initialValue);
	const [touched, setTouched] = React.useState(!!initialValue);
	const safeSet = React.useCallback(
		(a: string) => {
			if (!matcher || matcher.test(a)) {
				const t = transform ? transform(a) : a;
				setValue(t);
			}
		},
		[matcher, transform]
	);

	const onChange = React.useCallback(
		function(e: React.ChangeEvent<T>) {
			safeSet(e.target.value);

			if (!touched) {
				setTouched(true);
			}
		},
		[safeSet, touched]
	);

	return [
		value,
		{
			value,
			onChange
		},
		(v: string) => {
			setValue(v);
			setTouched(true);
		},
		touched
	];
}

export function useLoweredInput<T extends HTMLInputElement | HTMLTextAreaElement>(
	initialValue: string
) {
	return useInput(initialValue, null, f => f.toLowerCase());
}

type InputSubset<
	T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
> = InputHookProps<T> &
	Required<Pick<React.DetailedHTMLProps<React.InputHTMLAttributes<T>, T>, "onKeyPress">> &
	Partial<Pick<React.DetailedHTMLProps<React.InputHTMLAttributes<T>, T>, "type">>;

const NUM_ONLY_REGEX = /^(0+)?(\d*\.?\d*?)$/;
const MONEY_REGEX = /^(0+)?(\d*\.?\d?\d?)$/;

function _numOnlyInputHook(
	initialValue: number | null | undefined,
	matcher: RegExp
): [number, InputSubset, (v: number) => void, boolean] {
	const [value, setValue] = React.useState(
		undefined === initialValue || null === initialValue || Number.isNaN(initialValue)
			? 0
			: initialValue
	);
	const [enteredValue, setEnteredValue] = React.useState(
		initialValue ? initialValue.toString() : ""
	);
	const [touched, setTouched] = React.useState(!!initialValue);

	const updateValue = React.useCallback(
		(val: string) => {
			if (!val) {
				setValue(0);
			} else {
				const newVal = parseFloat(val);

				if (newVal !== value && !Number.isNaN(newVal)) {
					setValue(newVal);
				}
			}

			if (val === "0" || val === "0.") {
				setEnteredValue(val);
			} else {
				//-- Remove leading 0's
				setEnteredValue(val.replace(matcher, "$2"));
			}

			if (!touched) {
				setTouched(true);
			}
		},
		[value, matcher, touched]
	);

	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const val = e.target.value;
			updateValue(val);
		},
		[updateValue]
	);

	const onKeyPress = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const keyCode = e.keyCode || e.which;

			const keyValue = String.fromCharCode(keyCode);

			const curTarget = e.currentTarget || {
				selectionEnd: 0,
				selectionStart: 0
			};

			const selStart = curTarget.selectionStart || 0;
			const selEnd = curTarget.selectionEnd || 0;

			//-- Check if all input is selected
			const isAllSelected = selStart - selEnd === e.currentTarget.value.length;

			const newStr = isAllSelected ? keyValue : `${enteredValue}${keyValue}`;

			//-- If we add this char to the current string, is is valid?
			if (!matcher.test(newStr)) e.preventDefault();
		},
		[enteredValue, matcher]
	);

	return [
		value,
		{
			value: enteredValue,
			onKeyPress,
			onChange,
			type: "number"
		},
		v => updateValue(v.toString()),
		touched
	];
}

export function useNumberOnlyInput(
	initialValue?: number
): [number, InputSubset, (v: number) => void, boolean] {
	return _numOnlyInputHook(initialValue, NUM_ONLY_REGEX);
}

export function useMoneyInput(initialValue?: number) {
	return _numOnlyInputHook(initialValue, MONEY_REGEX);
}

export function useWholeNumberInputProps(
	initialValue: number
): [number, InputSubset, (v: number) => void] {
	const [value, setValue] = React.useState(
		Number.isNaN(initialValue) ? 0 : parseInt(initialValue.toString())
	);
	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const val = e.target.value;
			if (!val) {
				setValue(0);
			} else {
				const newVal = parseInt(val);

				if (newVal !== value) {
					setValue(newVal);
				}
			}
		},
		[value]
	);

	const onKeyPress = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const keyCode = e.keyCode || e.which;

			const keyValue = String.fromCharCode(keyCode);

			const newStr = `${value}${keyValue}`;

			//-- If we add this char to the current string, is is valid?
			if (!/^\d*$/.test(newStr)) e.preventDefault();
		},
		[value]
	);

	return [
		value,
		{
			value: value.toString(),
			onKeyPress,
			onChange,
			type: "number"
		},
		v => setValue(parseInt(v.toString()))
	];
}
