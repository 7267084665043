import * as React from "react";
import { CSSTransition } from "react-transition-group";
import "./headshake.scss";

export type HeadshakeDataProps = {
    spy: string | number;
}

export type HeadshakeActionProps = {

}

export type HeadshakeProps = HeadshakeDataProps & HeadshakeActionProps;

const HeadShake: React.FC<HeadshakeProps> = (props) => {

    const {
        children
    } = props;

    const [trigger, setTrigger] = React.useState(false);
    const r = React.useRef(false);

    React.useEffect(() => {
        if (r.current) {
            setTrigger(!trigger);
        }
    //-- We don't need setTrigger because that'd introduce an infinite loop
    // eslint-disable-next-line
    }, [props.spy]);

    React.useEffect(() => {
        r.current = true;
    }, []);

    return (
        <CSSTransition in={trigger} timeout={400} classNames={"headshake"} >
            {children}
        </CSSTransition>
    );
}


export default HeadShake;