import * as React from "react";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as xx } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { mountain, mountains, seedling, handHoldingSeedling, leaf, appleAlt } from "../lib/duotone";
library.add(mountain, mountains, seedling, handHoldingSeedling, leaf, appleAlt);

export type OSCDataProps = {};
export type OSCActionProps = {};

export type OSCProps = OSCDataProps & OSCActionProps;

const OSC: React.FC<OSCProps> = props => {
	return (
		<div className={"d-flex flex-column align-items-center"}>
			<div className={"d-flex align-items-end"}>
				{/* <span className={"text-primary"}>{"O"}</span> */}
				<div className={""}>
					<span className="fa-layers fa-fw">
						{/* <FontAwesomeIcon icon={["fad", "apple-alt"]} className={"text-white"}
                transform={"up-3 grow-5"}
                style={{ "--fa-secondary-color": "var(--success)", "--fa-secondary-opacity": "1", "--fa-primary-opacity": "0" } as any}
              /> */}

						<FontAwesomeIcon
							icon={["fad", "seedling"]}
							className={"text-success"}
							transform={"up-9 shrink-8"}
							style={
								{
									"--fa-secondary-color": "var(--success)",
									"--fa-secondary-opacity": "1"
								} as any
							}
						/>

						<FontAwesomeIcon icon={xx} className={"text-primary"} transform={""} />
						<FontAwesomeIcon icon={xx} className={"text-primary"} transform={"shrink-1"} />
						<FontAwesomeIcon icon={xx} className={"text-primary"} transform={"shrink-2"} />
						<FontAwesomeIcon icon={xx} className={"text-primary"} transform={"shrink-3"} />
					</span>
				</div>

				<span>{"range Supply Co"}</span>

				<div className={""} style={{ fontSize: ".2em", marginBottom: "1em" }}>
					<span className="fa-layers fa-fw">
						<FontAwesomeIcon
							icon={["fad", "seedling"]}
							className={"text-success"}
							transform={"up-9 shrink-8"}
							style={
								{
									"--fa-secondary-color": "var(--success)",
									"--fa-secondary-opacity": "1"
								} as any
							}
						/>

						<FontAwesomeIcon icon={faCircle} className={"text-primary"} transform={""} />
					</span>
				</div>
			</div>
		</div>
	);
};

export default OSC;
