import * as React from "react";
import ListingDetailTagsRow from "./listing-detail-tags-row";
import { handRock } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";

type RowFeaturesDataProps = {
	features: string[];
};
type RowFeaturesActionProps = {};
library.add(handRock);
const RowFeatures: React.SFC<
	RowFeaturesDataProps & RowFeaturesActionProps
> = props => {
	const { features } = props;

	return (
		<ListingDetailTagsRow
			glyph={handRock}
			leftText={"Features"}
			tags={features}
			renderTag={t => t}
		/>
	);
};

export default RowFeatures;
