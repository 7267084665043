import * as React from "react";
import { Switch, Route, Link } from "react-router-dom";
import i18n from "../../lib/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useNumberOnlyInput } from "../../hooks/use-input";
import SearchResults from "../search-results";
import { StoneysListDTO } from "../../lib/StoneTrashAPI";

library.add(faCircle);

export type FindMaterialDataProps = {
	request: StoneysListDTO;
	code: string;
};
export type FindMaterialActionProps = {};

export type FindMaterialProps = FindMaterialDataProps & FindMaterialActionProps;

export const FIND_ROOT = "/pro/find";
const SET_SIZE_URL = `${FIND_ROOT}/set-size`;
const SET_COLOR_URL = `${FIND_ROOT}/set-color`;
export const ROUTE_MATERIAL_RESULTS = `${FIND_ROOT}/results`;

const FindMaterial: React.FC<FindMaterialProps> = props => {
	const [color, set_color] = React.useState<string>();
	const [material, set_material] = React.useState<string>();
	const [sizeIdx, set_sizeIdx] = React.useState<number>();
	const [len, lenProps, setLen] = useNumberOnlyInput(0);
	const [wid, widProps, setWid] = useNumberOnlyInput(0);

	React.useEffect(() => {
		const found = dimension_options.find(d => d && d.length === len && d.width === wid);
		const idx = found ? dimension_options.indexOf(found) : -1;
		if (idx !== sizeIdx) {
			set_sizeIdx(idx);
		}
	}, [len, wid, sizeIdx]);

	return (
		<div>
			<Switch>
				<Route
					path={FIND_ROOT}
					exact
					render={() => (
						<>
							<p>{i18n.material_material_blurb}</p>
							<Route
								path="/"
								render={rp => (
									<div>
										{valid_materials
											.filter(m => !!m)
											.map(m => (
												<button
													key={m}
													className={BTN_SELECT_CLASS}
													onClick={() => {
														set_material(m);
														rp.history.push(SET_COLOR_URL);
													}}
												>
													{m}
												</button>
											))}
									</div>
								)}
							/>
							{/* <div className={"form-group"}>
								<label>{"Material"}</label>
								<select
									className={"custom-select"}
									value={material}
									onChange={e => set_material(e.target.value)}
								>
									{valid_materials.map(m => (
										<option value={m} key={m}>
											{m}
										</option>
									))}
								</select>
							</div> */}
							<div className={"border-bottom border-primary my-3 mx-n2"}></div>
							<Link to={SET_COLOR_URL} className={"btn btn-primary"}>
								{"Any Material Works"}
							</Link>
						</>
					)}
				/>
				<Route
					path={SET_SIZE_URL}
					exact
					render={() => (
						<>
							<p>{i18n.material_size_blurb}</p>
							<div className={"form-group"}>
								<label>{"Desired Dimensions"}</label>
								<select
									className={"custom-select"}
									value={sizeIdx ? JSON.stringify(sizeIdx) : ""}
									onChange={e => {
										if (e.target.value) {
											const i = parseInt(e.target.value);
											const val = dimension_options[i];
											set_sizeIdx(i);
											if (val) {
												setLen(val.length);
												setWid(val.width);
												return;
											}
										} else {
											set_sizeIdx(undefined);
										}
										setLen(0);
										setWid(0);
									}}
								>
									{dimension_options.map((d, i) => {
										if (d) {
											return (
												<option value={i} key={i}>
													{`${d.length} x ${d.width}`}
												</option>
											);
										}
										return (
											<option key={-1} value={""}>
												{""}
											</option>
										);
									})}
								</select>
							</div>
							<div className={"form-group d-flex align-items-center"}>
								<div className="input-group mr-3">
									<div className="input-group-prepend">
										<span className="input-group-text">{"Length"}</span>
									</div>
									<input type="text" className="form-control" {...lenProps} aria-label="Length" />
								</div>
								<div className="input-group ml-3">
									<div className="input-group-prepend">
										<span className="input-group-text">{"Width"}</span>
									</div>
									<input type="text" className="form-control" {...widProps} aria-label="Width" />
								</div>
							</div>
							<div className={"border-bottom border-primary my-3 mx-n2"}></div>
							<Link to={ROUTE_MATERIAL_RESULTS} className={"btn btn-primary"}>
								{i18n.material_size_set_button}
							</Link>
						</>
					)}
				/>
				<Route
					path={SET_COLOR_URL}
					exact
					render={() => (
						<>
							<p>{i18n.material_color_blurb}</p>
							<div className={"d-flex flex-wrap justify-content-around"}>
								<ColorButtion color={"Gray"} onClick={set_color} />
								<ColorButtion color={"Black"} onClick={set_color} />
								<ColorButtion color={"Tan"} onClick={set_color} />
								<ColorButtion color={"Brown"} onClick={set_color} />
								<ColorButtion color={"Red"} onClick={set_color} />
								<ColorButtion color={"Orange"} onClick={set_color} />
								<ColorButtion color={"Gold"} onClick={set_color} />
								<ColorButtion color={"Green"} onClick={set_color} />
								<ColorButtion color={"Blue"} onClick={set_color} />
								<ColorButtion color={"White"} onClick={set_color} />
							</div>
							<div className={"border-bottom border-primary my-3 mx-n2"}></div>
							<Link
								className={"btn btn-primary"}
								to={SET_SIZE_URL}
								onClick={() => set_color(undefined)}
							>
								{"Any color works."}
							</Link>
						</>
					)}
				/>
				<Route
					path={ROUTE_MATERIAL_RESULTS}
					render={() => (
						<SearchResults
							material={material}
							size={len && wid ? { length: len, width: wid } : undefined}
							code={props.code}
							color={color}
							request={props.request}
						/>
					)}
				/>
			</Switch>
		</div>
	);
};

const valid_materials = [
	"",
	"Basalt",
	"Concrete",
	"Engineered",
	"Granite",
	"Limestone",
	"Marble",
	"Onyx",
	"Porcelain",
	"Quartz",
	"Quartzite",
	"Semi-precious",
	"Slate",
	"Travertine"
];

export const dimension_options = [
	//	null,
	cd(2, 2),
	cd(3, 3),
	cd(3, 2),
	cd(4, 4),
	cd(4, 2),
	cd(6, 2),
	cd(6, 3),
	cd(6, 4),
	cd(8, 2),
	cd(8, 4),
	cd(8, 8),
	cd(10, 10),
	cd(12, 2),
	cd(12, 3),
	cd(12, 4),
	cd(12, 6),
	cd(12, 12),
	cd(18, 18),
	cd(24, 12),
	cd(24, 24)
];

function cd(length: number, width: number): IDimensions {
	return { length, width };
}

const BTN_SELECT_CLASS = "btn btn-dark mx-2 mt-2";
const ColorButtion: React.FC<{
	color: string;
	hex?: string;
	onClick: (c: string) => void;
}> = props => (
	<Link to={SET_SIZE_URL} className={BTN_SELECT_CLASS} onClick={() => props.onClick(props.color)}>
		<FontAwesomeIcon icon={faCircle} color={props.hex || props.color} /> {props.color}
	</Link>
);

export default FindMaterial;
