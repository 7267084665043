import * as React from "react";
import useSWR from "swr";
import { ApiClient, BASE_URL, SendEmail } from "../lib/API";
import { HashLoader } from "react-spinners";

import "react-photoswipe-2/lib/photoswipe.css";
import { PhotoSwipe } from "react-photoswipe-2";
import If from "./If";
import { getDisplaySpecial } from "./formatting/decimal";
import { GetThicknessDisplay } from "../lib/string-services";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
	faPalette,
	faStar,
	faRulerCombined,
	faRulerVertical,
	faInventory,
	faHandRock,
	faThLarge
} from "@fortawesome/pro-duotone-svg-icons";
import ColorGlyph from "./color-glyph";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { getImageDimensions } from "../lib/image-service";
import { Link } from "react-router-dom";

export type ListingPageDataProps = {
	listingId: number;
};
export type ListingPageActionProps = {};

export type ListingPageProps = ListingPageDataProps & ListingPageActionProps;

const ListingPage: React.FC<ListingPageProps> = props => {
	const { data, isValidating, error } = useSWR(`Listing_${props.listingId}`, f =>
		ApiClient.apiListingsGetListingPost({ id: props.listingId }, undefined)
	);
	const { data: items = [] } = useSWR(
		() => {
			if (!data || !data.listing || !data.listing.images) {
				throw new Error("Can't figure out");
			}
			return `Listing_Images__${props.listingId}_${data.listing.images.length}`;
		},
		async f => {
			if (!data || !data.listing || !data.listing.images) throw new Error("An error occured");

			const imageResult = data.listing.images.map(i => {
				const imgUrl = GetImageUrl(i.id as number);

				return new Promise<{
					src: string;
					w: number;
					h: number;
					id: number;
				}>(r => {
					getImageDimensions(imgUrl).then(dim => {
						r({
							src: imgUrl,
							h: dim.height,
							w: dim.width,
							id: i.id as number
						});
					});
				});
			});

			const r = await Promise.all(imageResult);
			return r;
		}
	);

	const [isOpen, set_isOpen] = React.useState<boolean>(false);

	if (!data || !data.listing) {
		return (
			<div className={"d-flex flex-column align-items-center mt-5"}>
				<HashLoader size={40} sizeUnit={"px"} color={"#F68E2D"} />
			</div>
		);
	}

	const listing = data.listing;

	const hasImages = !!listing.images && listing.images.length > 0;

	let options = {
		shareButtons: [
			{ id: "download", label: "Download image", url: "{{raw_image_url}}", download: true }
		]
	};

	var containerClass = "d-flex flex-row";
	var leftColClass =
		"col-6 col-lg-5 bg-light border-right py-2 pl-0 px-md-1 text-center text-left d-flex align-items-center font-proxima-semibold text-size-md-em2";
	var rightColClass = "col py-2 d-flex align-items-center font-proxima";
	var listItemClass = "list-group-item p-0";

	const glyphProps: Omit<FontAwesomeIconProps, "icon"> = {
		fixedWidth: true,
		size: "lg",
		className: "mr-2 mr-md-3 ml-2"
	};

	return (
		<div className="row px-0 mx-0">
			<If condition={hasImages}>
				{() => (
					<div className="col-12 col-lg-5 col-xl-6 px-0">
						<If condition={items.length === 0}>
							{() => (
								<div className={"d-flex flex-column align-items-center mt-5"}>
									<HashLoader size={40} sizeUnit={"px"} color={"#F68E2D"} />
								</div>
							)}
						</If>
						<If condition={items.length > 0}>
							{() => (
								<>
									<div
										id="carouselExampleIndicators"
										className="carousel slide"
										data-ride="carousel"
									>
										<ol className="carousel-indicators">
											{!!items
												? items.map((i, idx) => (
														<li
															data-target="#carouselExampleIndicators"
															data-slide-to={idx}
															className={idx === 0 ? "active" : ""}
														></li>
												  ))
												: null}
										</ol>
										<div className="carousel-inner">
											{!!items
												? items.map((i, idx) => (
														<div
															style={{ cursor: "pointer" }}
															className={`carousel-item ${idx === 0 ? "active" : ""}`}
															onClick={() => set_isOpen(true)}
														>
															<img src={i.src} className="d-block w-100" alt="..." />
														</div>
												  ))
												: null}
										</div>
										<a
											className="carousel-control-prev"
											href="#carouselExampleIndicators"
											role="button"
											data-slide="prev"
										>
											<span className="carousel-control-prev-icon" aria-hidden="true"></span>
											<span className="sr-only">Previous</span>
										</a>
										<a
											className="carousel-control-next"
											href="#carouselExampleIndicators"
											role="button"
											data-slide="next"
										>
											<span className="carousel-control-next-icon" aria-hidden="true"></span>
											<span className="sr-only">Next</span>
										</a>
									</div>
									<PhotoSwipe
										isOpen={isOpen}
										items={items}
										options={options}
										onClose={() => set_isOpen(false)}
									/>
								</>
							)}
						</If>
					</div>
				)}
			</If>

			<div className="col-12 col-lg-7 col-xl-6 px-0 d-flex flex-column">
				<div className="pt-3 pt-lg-0 px-md-5 px-2">
					<div className="mx-2 mx-md-0">
						<div className="">
							<div className="float-right ml-1">
								{/* <partial name="_SocialShare" model="socialModel" />
                                <partial name="_FavoriteButton" model="item.Id" /> */}
							</div>
							<div>
								{/* <div>
										<small>Id #: {listing.id}</small>
									</div> */}
								<h2 className="font-proxima-semibold">{listing.title}</h2>
							</div>
						</div>
						<h5 className="font-proxima-semibold text-uppercase">
							<Link to={`/search?manufacturer=${listing.manufacturer_Tag}`}>
								{listing.manufacturer_Tag}
							</Link>
						</h5>
					</div>

					{listing.notes ? (
						<div className="alert alert-primary">
							<p className="px-2 mb-0 px-md-0 font-proxima">{listing.notes}</p>
						</div>
					) : null}

					<div className="text-dark">
						<ul className="list-group list-group-flush border mb-4">
							<li className={listItemClass}>
								<div className={containerClass}>
									<div className={leftColClass}>
										<FontAwesomeIcon {...glyphProps} icon={faRulerCombined} title="Dimensions" />
										{"Dimensions"}
									</div>
									<div className={rightColClass}>
										{`${getDisplaySpecial(
											listing.dimensionLongest_Inches,
											"atmost"
										)}" x ${getDisplaySpecial(listing.dimensionShortest_Inches, "atmost")}"`}
									</div>
								</div>
							</li>
							<li className={listItemClass}>
								<div className={containerClass}>
									<div className={leftColClass}>
										<FontAwesomeIcon {...glyphProps} icon={faRulerVertical} title="Thickness" />
										{"Thickness"}
									</div>
									<div className={rightColClass}>
										{GetThicknessDisplay(listing.dimensionThickness_SixteenthInches)}
									</div>
								</div>
							</li>
							<li className={listItemClass}>
								<div className={containerClass}>
									<div className={leftColClass}>
										<FontAwesomeIcon {...glyphProps} icon={faInventory} title="Stock" />
										{"Stock"}
									</div>
									<div className={rightColClass}>
										<div className="d-flex flex-wrap">
											<div>
												{`${getDisplaySpecial(
													listing.sqFtPerUnit * listing.qtyUnitsAvailable,
													"atmost"
												)} sq. ft.`}
											</div>
											{/* <div>
												<span className="ml-1 small">
													{`${listing.qtyUnitsAvailable} ${GetSlabBoxString(
														listing.format_Tag,
														listing.qtyUnitsAvailable
													)}`}
												</span>
											</div> */}
										</div>
									</div>
								</div>
							</li>
							<li className={listItemClass}>
								<div className={containerClass}>
									<div className={leftColClass}>
										<FontAwesomeIcon {...glyphProps} icon={faHandRock} title="Finish" />
										{"Finish"}
									</div>
									<div className={rightColClass}>
										{listing.finishes ? listing.finishes.join(" | ") : ""}
									</div>
								</div>
							</li>
							{listing.materials_Tags && listing.materials_Tags.length > 0 ? (
								<li className={listItemClass}>
									<div className={containerClass}>
										<div className={leftColClass}>
											<FontAwesomeIcon {...glyphProps} icon={faThLarge} title="Materials" />

											{"Materials"}
										</div>
										<div className={rightColClass}>{listing.materials_Tags.join(" | ")}</div>
									</div>
								</li>
							) : null}

							<If condition={!!listing.subFormats && listing.subFormats.length > 0}>
								{() => (
									<li className={listItemClass}>
										<div className={containerClass}>
											<div className={leftColClass}>
												<FontAwesomeIcon {...glyphProps} icon={faStar} title={"Features"} />
												{"Features"}
											</div>
											<div className={rightColClass}>
												{listing.subFormats ? listing.subFormats.join(" | ") : ""}
											</div>
										</div>
									</li>
								)}
							</If>

							<If condition={!!listing.colors_Tags && listing.colors_Tags.length > 0}>
								{() => (
									<li className={listItemClass}>
										<div className={containerClass}>
											<div className={leftColClass}>
												<FontAwesomeIcon icon={faPalette} title={"Colors"} {...glyphProps} />
												<i className="fad fa-palette @glyphCommonClasses" title="Colors"></i>{" "}
												{"Colors"}
											</div>
											<div className="col-md pt-2 pb-2 d-md-flex align-items-md-center">
												{listing.colors_Tags
													? listing.colors_Tags.map(c => <ColorGlyph color={c} key={c} />)
													: null}
											</div>
										</div>
									</li>
								)}
							</If>
						</ul>
					</div>
				</div>

				<div className={"px-1 px-lg-5"}>
					<button className={"btn btn-primary btn-block"} onClick={SendEmail}>
						<FontAwesomeIcon icon={faEnvelope} size="lg" fixedWidth className={"mr-3"} />
						{"E-Mail Orange Supply Co."}
					</button>
				</div>

				<div className="py-3 px-md-5 mx-mt-4 px-2 d-flex flex-column flex-md-row justify-content-around font-proxima-semibold">
					<div className="d-flex flex-column align-items-center">
						<strong className="">Price Per SQFT: </strong>
						<span className="text-primary text-size-em3">
							{`${getDisplaySpecial(listing.pricePerSqFt_USD, "money")} / sq.ft`}
						</span>
					</div>
					<div className="border-right"></div>
					<div className="d-flex flex-column align-items-center">
						<strong className="">Lot Price: </strong>
						<span className="text-primary text-size-em3">
							{getDisplaySpecial(
								listing.pricePerSqFt_USD * listing.sqFtPerUnit * listing.qtyUnitsAvailable,
								"money"
							)}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export function GetImageUrl(i?: number | null, maxImageSize?: number) {
	if (!i) return "";
	var imgSize = maxImageSize ? `?maxImageSize=${maxImageSize}` : "";
	return `${BASE_URL}/api/Photos/${i}${imgSize}`;
}

export default ListingPage;
