import * as React from "react";
import Decimal, { Money, getDisplaySpecial } from "../formatting/decimal";
import ListingDetailRow from "./listing-detail-row";
import { usdSquare } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";

type RowPriceDataProps = {
	pricePerSqFt_USD: number;
	totalSqFt?: number;
};
type RowPriceActionProps = {};

library.add(usdSquare);

const RowPrice: React.SFC<RowPriceDataProps & RowPriceActionProps> = props => {
	const { pricePerSqFt_USD, totalSqFt } = props;

	const isTotalSet = undefined !== totalSqFt && totalSqFt > 0;

	return (
		<ListingDetailRow
			glyph={usdSquare}
			leftText={isTotalSet ? "Total Price" : "Price / sq. ft"}
			right={
				<div className={"d-flex flex-wrap"}>
					{isTotalSet ? (
						<div>
							<Money value={pricePerSqFt_USD * (totalSqFt || 0)} className={"mr-2"} />
							<small className={"text-muted"}>{`for ${getDisplaySpecial(
								totalSqFt || 0,
								"atmost"
							)} sq. ft.`}</small>
						</div>
					) : (
						<Money value={pricePerSqFt_USD} className={"mr-2"} />
					)}
				</div>
			}
		/>
	);
};

export default RowPrice;
