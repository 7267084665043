import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faSquare as faSolidSquare } from "@fortawesome/pro-solid-svg-icons";
export type ColorGlyphDataProps = {
	color: string;
};
export type ColorGlyphActionProps = {};

export type ColorGlyphProps = ColorGlyphDataProps & ColorGlyphActionProps;

const ColorGlyph: React.FC<ColorGlyphProps> = props => {
	const c = props.color;

	const isWhite = c.toLocaleLowerCase() === "white";

	return (
		<Link className="ml-1" to={`/search?color=${c}`} key={c}>
			<FontAwesomeIcon
				fixedWidth
				size={"lg"}
				icon={isWhite ? faSquare : faSolidSquare}
				style={{ color: isWhite ? undefined : c }}
				className={isWhite ? "text-dark" : ""}
			/>
		</Link>
	);
};

export default ColorGlyph;
