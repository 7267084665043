import * as React from "react";
import ListingDetailTagsRow from "./listing-detail-tags-row";
import { pallet } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FirstCap } from "../../lib/string-services";

type RowColorsDataProps = {
	colors: string[];
};
type RowColorsActionProps = {};
library.add(pallet);

const RowColors: React.SFC<RowColorsDataProps & RowColorsActionProps> = props => {
	const { colors } = props;

	return (
		<ListingDetailTagsRow
			glyph={pallet}
			leftText={"Colors"}
			tags={colors}
			renderTag={t => (
				<div className={"mr-2"} key={t}>
					{FirstCap(t)}
				</div>
			)}
		/>
	);
};

export default RowColors;
