import * as React from "react";
import Fade from "../animations/fade";
import Zoom from "../animations/zoom";
import Logo from "./logo";
import OSC from "./osc";
import { Link } from "react-router-dom";
import CenterBox from "./layout/center-box";

export type WelcomeComponentDataProps = {};
export type WelcomeComponentActionProps = {};

export type WelcomeComponentProps = WelcomeComponentDataProps & WelcomeComponentActionProps;

const WelcomeComponent: React.FC<WelcomeComponentProps> = props => {
	const [isLogoVisible, setIsLogoVisible] = React.useState(false);
	const [isBlurbVisible, setIsBlurbVisible] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setIsLogoVisible(true);
		}, 500);
	}, []);

	return (
		<CenterBox>
			<div>
				<Zoom
					in={isLogoVisible}
					onEntered={() =>
						setTimeout(() => {
							setIsBlurbVisible(true);
						}, 1000)
					}
				>
					<div>
						<Logo />
						<h1 className="cover-heading shadowText">
							<OSC />
						</h1>
					</div>
				</Zoom>
				<Fade appear when={isBlurbVisible}>
					<div>
						<p className="lead shadowText">
							{
								"Orange Supply Co. (OSC) is dedicated to procuring the very best in materials for our clients. Our unique strategy offers unrivaled value."
							}
						</p>
						<p className="lead shadowText">{""}</p>
						<div className={"d-flex justify-content-around mt-3"}>
							<Link to={"/search"} className={"btn btn-primary"}>
								{"Start Your Search"}
							</Link>
						</div>
					</div>
				</Fade>
			</div>
		</CenterBox>
	);
};

export default WelcomeComponent;
