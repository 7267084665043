import * as React from "react";
import ListingDetailRow from "./listing-detail-row";
import { getDisplaySpecial } from "../formatting/decimal";
import { inventory } from "../../lib/duotone";
import { library } from "@fortawesome/fontawesome-svg-core";

type RowStockDataProps = {
	qtyAvailable: number;
	sqftPerUnit: number;
	format: string;
};
type RowStockActionProps = {};
library.add(inventory);

const RowStock: React.SFC<RowStockDataProps & RowStockActionProps> = props => {
	const { qtyAvailable, sqftPerUnit } = props;

	return (
		<ListingDetailRow
			glyph={inventory}
			leftText={"Stock"}
			right={`${getDisplaySpecial(qtyAvailable * sqftPerUnit, "atmost")} sq. ft.`}
		/>
	);
};

export default RowStock;
