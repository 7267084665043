const inMemStorage: { [x: string]: string } = {};

const safeLS = window.localStorage || {
	clear: () => {},
	getItem: k => {
		if (inMemStorage[k]) {
			return inMemStorage[k];
		}
	},
	key: k => 0,
	length: Object.keys(inMemStorage).length,
	removeItem: k => {
		if (inMemStorage[k]) {
			delete inMemStorage[k];
		}
	},
	setItem: (k, v) => {
		inMemStorage[k] = v;
	}
};

function getItem(key: string) {
	return safeLS.getItem(key);
}

function storeItem(key: string, val: string) {
	safeLS.setItem(key, val);
}

function clearItem(key: string) {
	safeLS.removeItem(key);
}

export function GetVal<T>(key: string) {
	var value = getItem(key);

	if (value !== undefined && value !== null) {
		return JSON.parse(value) as T;
	}

	return null;
}

export function GetStringValue(key: string) {
	return getItem(key) || null;
}

export function SetVal<T>(key: string, item: T) {
	if (item !== null && item !== undefined) {
		var str = JSON.stringify(item);
		storeItem(key, str);
	} else {
		clearItem(key);
	}
}

export function SetString(key: string, item: string) {
	if (item !== null && item !== undefined) {
		storeItem(key, item);
	} else {
		clearItem(key);
	}
}

const CODE_KEY = "ENTERED_CODE";
export function StoreCode(code?: string) {
	if (!code) {
		clearItem(CODE_KEY);
	} else {
		SetString(CODE_KEY, code);
	}
}

export function GetCode() {
	return GetStringValue(CODE_KEY);
}
